import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js';

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textLeft)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            The Study Australia Entrepreneurship Challenge (SAEC) aims to engage
            high quality year 11 students from Indian high schools to
            participate in a TedX-meets-Hackathon style series of hybrid events.
            The participating students will form multidisciplinary teams and
            will participate in a three-week program which will showcase the
            opportunities of Australia’s education sector and the Australian
            entrepreneurial and start-up ecosystems.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
