/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
// sections for this page
import SectionDescription from 'views/PresentationPage/Sections/SectionDescription.js';
import SectionOverview from 'views/PresentationPage/Sections/SectionOverview.js';
import SectionTeams from 'views/PresentationPage/Sections/SectionTeams.js';
import SectionJudges from 'views/PresentationPage/Sections/SectionJudges.js';
import SectionFacilitators from 'views/PresentationPage/Sections/SectionFacilitators.js';
import SectionInnovationTours from 'views/PresentationPage/Sections/SectionInnovationTours';

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';
import './PresentationPage.scss';
import CommonFooter from 'views/CommonFooterPage/CommonFooter';

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        // brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: 'info',
        }}
      />
      <Parallax
        image={require('assets/img/HomePageimageV4C.jpg')}
        // filter="dark"
        // small
        className="custom-image"
      >
        {/* <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand + ' brand'}>
                <h1>Study Australia Entrepreneurship Challenge</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div> */}
      </Parallax>
      <div
        className={
          classNames(classes.main, classes.mainRaised) +
          ' ' +
          'custom-home-descr-margin'
        }
      >
        <SectionDescription />
        {/* <SectionTeams /> */}
        {/* <SectionInnovationTours /> */}
        {/* <SectionFacilitators /> */}
        {/* <SectionOverview /> */}
        {/* <SectionJudges /> */}
        {/* <SectionContent /> */}
        {/* <SectionComponents /> */}
        {/* <SectionCards /> */}
        {/* <SectionContent /> */}
        {/* <SectionSections /> */}
        {/* <SectionExamples /> */}
        {/* <SectionFreeDemo /> */}
        {/* <SectionOverview /> */}
      </div>
      {/* <SectionPricing /> */}
      <CommonFooter />
    </div>
  );
}
