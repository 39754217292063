export const KeyNoteSpeakerData = {
  2022: [
    {
      id: 1,
      name: 'Yvonne Power',
      imageURL: require('assets/img/faces/KeySpeaker/KeySpeakerYvonnePower.jpg'),
      mutedText: 'Co-founder & COO at Village Energy',
      description:
        'Yvonne Power is passionate about building a sustainable energy future, especially in communities and developing nations. Yvonne is co-founder and Chief Operations Officer of Village Energy, a WA born start-up that has developed energy technology to operate at the edge of the energy grid and deliver cleaner, cheaper and more reliable power to underserved populations. Yvonne led the development of a highly successful pilot project in Andhra Pradesh, India, with which WA has a sister-state relationship. Since the pilot, Village Energy has signed agreements across India, Indonesia, Vietnam and Cambodia and has a potential pipeline to millions of customer connections. Village Energy is backed by a Bill Gates chaired venture capital fund, Breakthrough Energy Ventures, and has been recognized as an APAC25 company and is the 2020 winner of the Australian Technologies Competition in energy. Yvonne is a mother of three, 2020 40 under 40 award winner, and formerly worked at Horizon Power as Manager Strategy, helping to drive the shift to renewable and distributed energy microgrids.',
    },
    {
      id: 2,
      name: 'Dr. Uttam Mittal',
      imageURL: require('assets/img/faces/KeySpeaker/KeySpeakerUttamMittal.jpg'),
      mutedText:
        'Postdoctoral Fellow, School of Chemistry, University of New South Wales',
      description:
        'Dr. Uttam Mittal is currently a Postdoctoral Fellow at the School of Chemistry at UNSW Sydney. He received his B.Tech. degree from Indian Institute of Technology (IIT), Delhi in 2014. He was the winner of the “Win Your Future Unlimited” competition organised by Austrade in 2013 out of 37000 applicants from over 190 countries. He completed his M.S. and PhD degree from the School of Materials Science and Engineering at UNSW Sydney in 2015 and 2019, respectively. He is very passionate about environmental sustainability. His research focuses on the synthesis, modification, analytical characterization, and electrochemical testing of high-performance electrode materials for Li/Na/K/Zn-ion batteries for next generation energy storage systems aimed at increased use of renewable energy resources to meet the growing energy needs around the world in a safe and sustainable manner.',
    },

    {
      id: 3,
      name: 'Ali Abbas',
      imageURL: require('assets/img/faces/Mentors/MentorAliAbbas.jpg'),
      mutedText:
        'Professor of Chemical Engineering and Acting Head of School, University of Sydney',
      description:
        'Professor Ali Abbas received both his Bachelors and PhD in Chemical Engineering from University of Sydney, Australia. His engineering research is in the area of Process Systems Engineering with emphasis on model-based optimal operation of particulate, energy and bio- systems. He has strong interests in education and teaching with particular focus on curriculum design and integration as well as on experiential e-learning and virtual worlds (such as the University’s Second Life world). Professor Abbas was Chair of the organising committee for the inaugural ‘Australian Circular Economy Conference’ (ACEC)',
    },
    {
      id: 4,
      name: 'Andrew Woodward',
      imageURL: require('assets/img/faces/Mentors/MentorAndrewWoodward.jpeg'),
      mutedText:
        'Executive Dean of the School of Science at Edith Cowan University',
      description:
        'Andrew in his leadership role at ECU, has a strong commitment to quality teaching, engaged research and a focus on expanding international partnerships. He believes strongly in ECUs values, particularly those of integrity and respect. He is a strong supporter of gender equality, and actively supports projects which aim to increase inclusivity in the STEM subjects. He also supports this goal through his role on the Athena SWAN steering committee at ECU. His research has been focused on cyber security for the last 15 years, focusing initially on wireless network security, expanding to digital forensics and then to cyber security policy and risk management. Concurrently, he has consulted to industry and government on a range of cyber security related projects, and is regularly invited to speak on cyber security at National and International Conferences and Events. He holds the degrees of Bachelor of Science (Hons), Doctor of Philosophy and the Graduate Certificate of Tertiary Teaching. He is also a certified professional member of the Australian Computer Society MACS(CP).',
    },
    {
      id: 5,
      name: 'Barney Tan',
      imageURL: require('assets/img/faces/Mentors/MentorBarneyTan.jpeg'),
      mutedText:
        'Head of School & Professor, Business School, School of Information Systems And Technology Management, University of New South Wales ',
      description:
        'Barney is the Head of School and a Professor at the School of Information Systems and Technology Management (SISTM) of UNSW Business School. He graduated with a PhD in Information Systems from the National University of Singapore, and was formerly Professor of Strategic Information Systems and Deputy Head of the Discipline of Business Information Systems at The University of Sydney. Barney’s research has been published in MIS Quarterly, Information Systems Research, Journal of Association of Information Systems, and Information Systems Journal. He is also currently a Senior Editor at Information Systems Journal and Information Technology and People, as well as an Associate Editor at Information and Management and Internet Research. Barney is an award-winning educator and research supervisor over his time at The University of Sydney, receiving a total of 22 awards in 11 years, including the Sydney University Postgraduate Representative Association (SUPRA) Teacher of the Year award for 2019 and 2020, the SUPRA Supervisor of the Year award for 2019, 2020 and 2021, as well as the inaugural The University of Sydney Business School Dean’s Award for Teaching in 2019.',
    },
    {
      id: 6,
      name: 'Farah Magrabi',
      imageURL: require('assets/img/faces/Mentors/MentorFarahMagrabi.jpg'),
      mutedText:
        'Professor, Australian Institute of Health Innovation, Macquarie University',
      description:
        'Farah Magrabi is a Professor of Biomedical and Health Informatics at the Australian Institute of Health Innovation, Macquarie University. She has a background in Electrical and Biomedical Engineering and is an expert in the design and evaluation of digital health and Artificial Intelligence (AI) technologies for clinicians and consumers. Professor Magrabi is internationally recognised as a leader in the safety of digital health and has made major contributions to documenting the patient safety risks of digital health technologies. Her research has changed practice to detect IT risks to patients and has shaped policy to address digital health safety in Australia and overseas. She is an inaugural recipient of the Sax Institute’s Research Action Award (2015) and Telstra Health’s Brilliant Women in Digital Health award (2021). She is currently investigating the integration and use of AI systems in real-world healthcare settings.',
    },
    // {
    //   id: 5,
    //   name: 'Ibrahim Khalil',
    //   imageURL: require('assets/img/faces/Mentors/MentorIbrahimKhalil.jpeg'),
    //   mutedText:
    //     'Professor in School of Computing Studies, Royal Melbourne Institute of Technology',
    //   description:
    //     'Ibrahim Khalil is a professor in School of Computing Technologies (Computer Science), RMIT University, Melbourne, Australia. Ibrahim obtained his Ph.D. in 2003 from the University of Berne in Switzerland. He has several years of experience in Silicon Valley, California based networking companies as software engineer working on secure network protocols and smart network provisioning. Ibrahim also worked for EPFL and University of Berne in Switzerland and Osaka University in Japan before joining RMIT University, Australia. Ibrahim is the Chief Investigators of a few prestigious ARC discovery and linkage grants awarded in Australia between 2017 and 2021. In the last two years (2020 and 2021) he received two ARC Discovery grants on Blockchain and Privacy as the sole Chief Investigator. Ibrahim is also the recipients of international European grants, industry grants, and QNRF grant from Qatar. His research interests include Privacy, Blockchain, secure AI &amp; data analytics. In the past he also worked on distributed systems, e-health, wireless and body sensor networks, biomedical signal processing, network security. Ibrahim is also a passionate teacher, and teaches courses on security, networking, and cloud. He is the recipient of several teaching awards.',
    // },
    {
      id: 7,
      name: 'Levi Swann',
      imageURL: require('assets/img/faces/Mentors/MentorLeviSwann.jpg'),
      mutedText:
        'Senior Lecturer in Industrial Design, Queensland University of Technology',
      description:
        'I am currently a Senior Lecturer in Industrial Design at QUT. My experience working in design research is relatively broad, typically focused on technology deployment in various contexts. Most recently this has included health technology, mobility, and agricultural technology. Prior to this I had conducted considerable work in airport security. Given this experience I am likely best positioned in the two themes of digital health and creative industries, with a focus on design of course.',
    },
    {
      id: 8,
      name: 'Michael Balfour',
      imageURL: require('assets/img/faces/Mentors/MentorMichaelBalfour.jpg'),
      mutedText:
        'Professor of Theatre and Performance, Head of School of Arts and Media, University of New South Wales',
      description:
        'Michael’s research is in the social applications of theatre. He has worked on international studies exploring arts–based work with returning veterans, performances in sites of war and conflict, performing arts practice in prisons and correctional facilities, and with newly arrived refugees in Australia.',
    },
    {
      id: 9,
      name: 'Usha Iyer-Raniga',
      imageURL: require('assets/img/faces/Mentors/MentorUsha.jpeg'),
      mutedText:
        'Professor at the School of Property, Construction and Project Management, Royal Melbourne Institute of Technology',
      description:
        'Usha is an experienced senior academic and researcher with a demonstrated history of working in the higher education industry. Skilled in Sustainable Development, Sustainability, Renewable Energy, Sustainable Business, and Environmental Policy. Strong and experienced sustainability in the built environment professional with national, regional and international experience.',
    },
    {
      id: 10,
      name: 'Glenn Withers',
      imageURL: require('assets/img/faces/Mentors/MentorGlennWithers.jpg'),
      mutedText: 'Professor of Economics, Australian National University',
      description:
        'Professor Glenn Withers is a Distinguished Honorary Professor at the Australian National University and Visiting Professor at the University of New South Wales Canberra. His Harvard PHD was on the topic of human resources for defense. He has held appointments at Harvard University and Cambridge University, as well as ANU, and has consulted widely for governments and companies from the OECD and the North-West Shelf Consortium to the US Defense Department and the Prime Minister of Malaysia. In Australia. He has been head of the National Population Council and the Economic Planning Advisory Commission and helped to establish the Bureau of Labour Market Research, the Bureau of Immigration research, the Productivity Commission, Crawford School of Government and Universities Australia. He was awarded honors by the Australian government for developing the Australian Immigration Points System. He is immediate past President of the Academy of the Social Sciences in Australia and the Australian Council of Learned Academies. Currently, he is Chair of the Global Board of the Global Development Learning Network, a World Bank affiliate that operates in 60 countries. He has a wide range of publications in books, academic journals, government reports and consultancy reports, particularly focusing on education, skills, workforce and population issues.',
    },
    {
      id: 11,
      name: 'Dr Jo Hawkins',
      imageURL: require('assets/img/faces/Entrepreneurs/EntrDrJoHawkins.jpeg'),
      mutedText: 'Innovation Manager, University of Western Australia',
      description:
        'Dr. Hawkins champions and facilitates industry engagement and innovation to enhance research impact and the student experience. She develops, manages and evaluates a portfolio of prototype projects under tight deadlines and budgets, utilising methodologies including lean start-up and design thinking. She employs a strategic approach to design and implement strategic initiatives to support cultural change. Dr. Hawkins has strong competencies in communication, stakeholder management, training, mentoring and facilitation.',
    },
    {
      id: 12,
      name: 'Murray Hurps',
      imageURL: require('assets/img/faces/Entrepreneurs/EntrMurrayHurps.png'),
      mutedText:
        'Director of Entrepreneurship, University of Technology Sydney',
      description:
        'Murray Hurps is UTS’ first Director of Entrepreneurship, tasked with growing the number of student-founded startups coming out of UTS and nurturing the Ultimo precinct’s vibrant startup environment. Murray plays a key role in developing and sustaining pathways connecting UTS graduates and student founders to the startup ecosystem locally, regionally, and internationally, including commercial accelerators, venture capital and international destinations. Murray also leads initiatives to ensure Ultimo continues to expand its historical position as a significant contributor to Sydney’s startup ecosystem.',
    },
    {
      id: 13,
      name: 'Nimrod Klayman',
      imageURL: require('assets/img/faces/Entrepreneurs/EntrNimrodKlayman.jpeg'),
      mutedText: 'Head of Entrepreneurship, University of Queensland',
      description:
        'Nimrod Klayman has more than 20 years experience operating in leading roles within different sectors, with experience working in start-ups and within large corporations based in Australia, Israel, and Argentina. Besides, Nimrod is a serial founder passionate about building new ventures and finding new opportunities. Nimrod currently leads the University of Queensland’s (Top 50 world-class university) Entrepreneurship programs UQ Ventures. UQ Ventures provides a home for entrepreneurs and Innovators that equips aspiring student entrepreneurs with the requisite skills and knowledge needed to conceive a start-up. Nimrod, through his extended global connections, created and leads the ""Global Startup Adventure"", a unique overseas innovation and entrepreneurship experience, based at some of the hottest global centres for entrepreneurship and innovation in Shanghai, Shenzhen, Tel Aviv, San Francisco and Singapore.',
    },
  ],
};
