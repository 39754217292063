import React from 'react';
// import { Link } from 'react-router-dom';
import Footer from 'components/Footer/Footer.js';
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';
import FRANKlogo from 'assets/img/assets-for-demo/ourClients/FRANKlogo.png';
import Button from 'components/CustomButtons/Button.js';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles(presentationStyle);

export default function CommonFooter() {
  const classes = useStyles();
  return (
    <Footer
      theme="white"
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                Delivered by{' '}
                <a
                  href="https://www.franked.io"
                  target="_blank"
                  className={classes.footerBrand}
                  rel="noopener noreferrer"
                >
                  franked.io
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.franked.io"
                  target="_blank"
                  className={classes.footerBrand}
                  rel="noopener noreferrer"
                >
                  <img
                    src={FRANKlogo}
                    alt="franked.io"
                    height={'20px'}
                    width={'20px'}
                  />
                </a>
              </ListItem>
            </List>
          </div>

          <div className={classes.pullCenter}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.studyaustralia.gov.au/"
                  target="_blank"
                  className={classes.footerBrand}
                  rel="noopener noreferrer"
                >
                  Follow Study Australia
                </a>

                {/* <Link to="/about-us" className={classes.block}>
                  About Us
                </Link> */}
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Button
                  href="https://www.facebook.com/StudyAUOfficial"
                  target="_blank"
                  color="facebook"
                  justIcon
                  simple
                >
                  <i className="fab fa-facebook" />
                </Button>
                <Button
                  href="https://www.instagram.com/studyauofficial/"
                  target="_blank"
                  color="instagram"
                  justIcon
                  simple
                >
                  <i className="fab fa-instagram" />
                </Button>
                <Button
                  href="https://www.linkedin.com/company/australian-trade-commission/"
                  target="_blank"
                  color="linkedin"
                  justIcon
                  simple
                >
                  <i className="fab fa-linkedin" />
                </Button>

                {/* <Link to="/participant-information" className={classes.block}>
                  Participant Information
                </Link> */}
              </ListItem>
              {/* <ListItem className={classes.inlineBlock}>
                <Link to="/mentor-information" className={classes.block}>
                  Mentor Information
                </Link>
              </ListItem> */}
              {/* <ListItem className={classes.inlineBlock}>
                  <Link to="/blog-posts" className={classes.block}>
                    Blogs
                  </Link>
                </ListItem> */}
            </List>
          </div>
          {/* <div className={classes.rightLinks}> */}
          <div className={classes.rightLinks}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://au.linkedin.com/company/franked-io"
                  target="_blank"
                  className={classes.footerBrand}
                  style={{ display: 'table-row' }}
                  rel="noopener noreferrer"
                >
                  Follow franked.io
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Button
                  href="https://au.linkedin.com/company/franked-io"
                  target="_blank"
                  color="linkedin"
                  justIcon
                  simple
                >
                  <i className="fab fa-linkedin" />
                </Button>
              </ListItem>
            </List>

            {/* <ul> */}
            {/* <li>
                  <Button
                    href="https://www.facebook.com/frankteam"
                    target="_blank"
                    color="facebook"
                    justIcon
                    simple
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </li> */}
            {/* <li>
                <Button
                  href="https://au.linkedin.com/company/franked-io"
                  target="_blank"
                  color="linkedin"
                  justIcon
                  simple
                >
                  <i className="fab fa-linkedin" />
                </Button>
              </li> */}
            {/* </ul> */}
          </div>
        </div>
      }
    />
  );
}
