import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core';
// core components
import ModalButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';

import CommonFooter from 'views/CommonFooterPage/CommonFooter';

//styling
import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';
import sectionCardsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js';
import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';

// Prizes page data
import { PrizesData } from './PrizesData';

const useStyles = makeStyles(teamsStyle);
const useCardStyles = makeStyles(sectionCardsStyle);
const useModalStyles = makeStyles(testimonialsStyle);

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

export default function Prizes({ ...rest }) {
  const [classicModal, setClassicModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  const classes = useStyles();
  const classesCard = useCardStyles();
  const classesModal = useModalStyles();

  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>

      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>Prizes</h2>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {PrizesData[2024].map((videoDetails, index) => (
              <GridItem
                xs={12}
                sm={12}
                md={6}
                key={index}
                className="custom-grid-video-card-padding"
              >
                <Card
                  // className={classes.cardRoot}
                  className={`${classes.cardRoot} custom-card-video-prizes`}
                >
                  {/* <CardMedia
                    // className={classes.cardMedia}
                    // component="iframe"
                    className="custom-card-media custom-card-prizes-media"
                    image={videoDetails.logo}
                  /> */}
                  <div>
                    <CardContent
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {videoDetails.logos.map((logo, i) => (
                        <img
                          key={i}
                          src={logo}
                          className="custom-card-prizes-media"
                          alt={'Company Logo'}
                          style={{
                            width:
                              videoDetails.logos.length === 2 ? '50%' : '100%',
                          }}
                        />
                      ))}
                    </CardContent>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {videoDetails.mainHeading}
                      </Typography>

                      <br />

                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h6"
                        style={{ textTransform: 'none' }}
                      >
                        {videoDetails.subHeading1}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {htmlToReactParser.parse(videoDetails.bodyText1)}
                      </Typography>
                      {/* <br /> */}
                    </CardContent>
                  </div>
                  {/* <Typography gutterBottom variant="h5" component="h5">
                      {videoDetails.subHeading2}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {htmlToReactParser.parse(videoDetails.bodyText2)}
                    </Typography> */}
                  {videoDetails.termsAndCondition &&
                    <CardContent style={{ padding: '16px' }}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="small"
                        // align="right"
                        style={{
                          fontSize: '0.670rem',

                        }}
                      >
                        {htmlToReactParser.parse(
                          videoDetails.termsAndCondition
                        )}
                      </Typography>
                    </CardContent>
                  }
                  {/* <CardContent className="custom-card-action-video-prizes">
                    <div
                      className={`${classesCard.stats} ${classesCard.justifyContentCenter}`}
                    >
                      <ModalButton
                        round
                        className="btn-custom-green"
                        onClick={() => {
                          setClassicModal(true);
                          setModalData(videoDetails);
                        }}
                      >
                        {videoDetails.subHeading2}
                      </ModalButton>
                    </div>
                  </CardContent> */}

                  {/* <CardActions>
                    {videoDetails && videoDetails.learnMoreLink !== '' ? (
                      <Button
                        size="small"
                        style={{ textTransform: 'none' }}
                        target="_blank"
                        href={videoDetails.learnMoreLink}
                      >
                        {videoDetails.learnMoreText}
                      </Button>
                    ) : (
                      ''
                    )}

                    {videoDetails && videoDetails.learnMoreLink2 !== '' ? (
                      <Button
                        size="small"
                        style={{ textTransform: 'none' }}
                        target="_blank"
                        href={videoDetails.learnMoreLink2}
                      >
                        {videoDetails.learnMoreText2}
                      </Button>
                    ) : (
                      ''
                    )}
                  </CardActions> */}
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>

        <Dialog
          classes={{
            root: classesModal.modalRoot,
            paper: classesModal.modal,
          }}
          open={classicModal}
          keepMounted
          onClose={() => {
            setClassicModal(false);
            setModalData({});
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogContent
            id="classic-modal-slide-description"
            className={classesModal.modalBody}
          >
            {modalData && modalData.bodyText2 === '' ? (
              <p>Description is not available!</p>
            ) : (
              <p>{htmlToReactParser.parse(modalData.bodyText2)}</p>
            )}
          </DialogContent>
        </Dialog>
      </div>
      <CommonFooter />
    </div>
  );
}
