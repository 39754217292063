import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
// import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import b01 from 'assets/img/b01.jpg';

import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';

const useStyles = makeStyles(teamsStyle);

export default function TeamsPage2024() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        // brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: 'info',
        }}
      />

      <div className="cd-section">
        <div
          className={classes.team + ' ' + classes.section}
          style={{
            backgroundImage: `url(${b01})`,
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto +
                  ' ' +
                  classes.mrAuto +
                  ' ' +
                  classes.textCenter
                }
              >
                <h2 className={classes.titleWhite}>2024 SAEC Teams</h2>
                <h3 className={classes.titleWhite}>COMING SOON</h3>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem></GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      {/* <div className="cd-section">
        <div
          className={classes.team + ' ' + classes.section}
          style={{ backgroundImage: `url(${b01})` }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto +
                  ' ' +
                  classes.mrAuto +
                  ' ' +
                  classes.textCenter
                }
              >
                <h2 className={classes.titleWhite}>2022 SAEC Teams</h2>
                <h5 className={classes.descriptionWhite}>
                  Meet our amazing SAEC 2022 teams. These teams came together to
                  solve real-world global challenges.
                </h5>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div> */}
    </div>
  );
}

// TeamsPage2024.propTypes = {
//   title: PropTypes.string.isRequired,
//   teams: PropTypes.array.isRequired,
// };
