import React from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

// @material-ui icons
import Apps from '@material-ui/icons/Apps';
import ViewDay from '@material-ui/icons/ViewDay';
import ViewCarousel from '@material-ui/icons/ViewCarousel';

// react component used to create nice image meadia player
import ImageGallery from 'react-image-gallery';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Img1 from '../../../assets/img/saec2022/chiefEntrepreneurPhoto.jpg';
import Img2 from '../../../assets/img/saec2022/copy_of_gala_event.jpg';
import Img3 from '../../../assets/img/saec2022/copy_of_live_pitch.jpg';
import Img4 from '../../../assets/img/saec2022/kangaroo.jpg';
import Img5 from '../../../assets/img/saec2022/koala_pic.jpg';
import Img6 from '../../../assets/img/saec2022/study_adelaide_first _prize_winners.jpg';
import Img7 from '../../../assets/img/saec2022/study_nsw_second_prize_winners.jpg';
import Img8 from '../../../assets/img/saec2022/study_queensland_third_prize_winners.jpg';
import Img9 from '../../../assets/img/saec2022/tim_from_stone&chalk.jpg';
import Img10 from '../../../assets/img/saec2022/campusTour.jpg';
import Img11 from '../../../assets/img/saec2022/vineyard.jpg';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js';
import headersStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js';

const useStyles = makeStyles(descriptionStyle);
const useStyles1 = makeStyles(headersStyle);

export default function SectionDescription() {
  const classes = useStyles();
  const classes1 = useStyles();
  const headerClass = useStyles1();
  const images = [
    {
      original: Img10,
    },
    {
      original: Img1,
    },
    {
      original: Img2,
    },
    {
      original: Img3,
    },
    {
      original: Img4,
    },
    {
      original: Img5,
    },
    {
      original: Img6,
    },
    {
      original: Img7,
    },
    {
      original: Img8,
    },
    {
      original: Img9,
    },
    {
      original: Img10,
    },
    {
      original: Img11,
    },
  ];

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h5 className={classes.description}>
              The Study Australia Entrepreneurship Challenge (SAEC) aims to
              engage year 11 students from Indian high schools to participate in
              a TedX-meets-Hackathon style series of hybrid events. The
              participating students will form multidisciplinary teams and will
              participate in a three-week program which will showcase the
              opportunities of Australia’s education sector and the Australian
              entrepreneurial and start-up ecosystems. The students may also
              stand a chance to win an all-expense paid educational tour to
              Australia* and experience the lifestyle of students in Australia
              firsthand, along with several other prizes which may include
              scholarships to top Australian Universities and cash awards.
            </h5>
            <p className={classes.description}>*subject to availability*</p>
          </GridItem>
        </GridContainer>

        <div className={classes1.features}>
          <GridContainer className={(classes.mlAuto, classes.mrAuto)}>
            <GridItem md={8} sm={8} className={classes.textCenter}>
              <h3 className={headerClass.title}>
                2022 SAEC Winners Study Adelaide Student Tour
              </h3>

              <div className={classes.iframeContainer}>
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/4gGiX4SyiGQ?si=Ba1ceMvRrsFemGPb"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div className={classes.features}>
          <GridContainer>
            <GridItem md={8} sm={8}>
              <h3 className={headerClass.title}>SAEC 2022</h3>
              <div>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={true}
                  startIndex={1}
                  items={images}
                  showNav={false}
                  showBullets={true}
                  autoPlay={true}
                  slideDuration={500}
                  infinite={true}
                />
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="32 multidisciplinary student teams"
                description="Some of the brightest Indian minds working together to solve real-world global problems"
                icon={Apps}
                iconColor="greenHome"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Academic and Industry Mentors"
                description="Student teams are mentored by Australian academic and industry experts throughout the program"
                icon={ViewDay}
                iconColor="greenHome"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Three-weeks of fast paced entrepreneurial challenge"
                description="Students learn, connect and work virtually, applying the latest technology and business tools."
                icon={ViewCarousel}
                iconColor="greenHome"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
