import React from 'react';
// react component for creating beautiful carousel
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
// @material-ui/icons
// core components
// import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// import Card from 'components/Card/Card.js';
// import CardBody from 'components/Card/CardBody.js';
// import CardAvatar from 'components/Card/CardAvatar.js';
// import Muted from 'components/Typography/Muted.js';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import CommonFooter from 'views/CommonFooterPage/CommonFooter';
import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';

// import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js';
import { VideoLibraryData } from './VideoLibraryData';
const useStyles = makeStyles(testimonialsStyle);

export default function VideoLibraryPage({ ...rest }) {
  const classes = useStyles();

  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>
      {/* Testimonials 3 START */}
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>Video Library</h2>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {VideoLibraryData[2022].map((videoDetails, index) => (
              <GridItem
                xs={12}
                sm={6}
                md={6}
                key={index}
                className="custom-grid-video-card-padding"
              >
                <Card
                  className={`${classes.cardRoot} custom-card-video-prizes`}
                >
                  <Link href={videoDetails.thumbnailVideo} target="_blank">
                    <CardMedia
                      //   className={classes.cardMedia}
                      className="custom-card-media"
                      image={videoDetails.thumbnailCardImage}
                    />
                  </Link>

                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {videoDetails.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {videoDetails.description}
                    </Typography>
                  </CardContent>

                  <CardActions className="custom-card-action-video-prizes">
                    <Button
                      size="small"
                      style={{ textTransform: 'none' }}
                      target="_blank"
                      href={videoDetails.videoLink}
                    >
                      Watch Video
                    </Button>

                    {videoDetails && videoDetails.learnMoreLink !== '' ? (
                      <Button
                        size="small"
                        style={{ textTransform: 'none' }}
                        target="_blank"
                        href={videoDetails.learnMoreLink}
                      >
                        Learn More
                      </Button>
                    ) : (
                      ''
                    )}
                  </CardActions>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 3 END */}
      <CommonFooter />
    </div>
  );
}
