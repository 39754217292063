export const PrizesData = {
  2022: [
    {
      id: 1,
      logo: require('assets/img/prizes/StudyAdelaide2.jpg'),
      mainHeading: 'First Prize: Student tour to Australia in March 2023',
      subHeading1: 'Sponsored by StudyAdelaide',
      bodyText1:
        'The winners of SAEC 2022 will be awarded a Student Tour to Australia in March 2023 sponsored by Study Adelaide. The team will experience the Australian lifestyle, go sightseeing as well as be able to visit and learn more about Australian education institutions and Australian start-up culture.',
      subHeading2: 'About StudyAdelaide',
      bodyText2:
        'StudyAdelaide is a public corporation that grows awareness and preference for Adelaide as a destination for international students across all sectors, including schools, vocational and higher education. StudyAdelaide works in partnership with education providers to deliver a rewarding experience for international students studying in Adelaide.',
      learnMoreText: 'Watch Video',
      learnMoreLink:
        'https://www.youtube.com/watch?v=1C7FMaq7unw&feature=youtu.be',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://studyadelaide.com/india',
    },
    {
      id: 2,
      logo: require('assets/img/prizes/NSWGovStudyNSW.png'),
      mainHeading: 'Second Prize: $2000 Scholarship per student',
      subHeading1: 'Sponsored by Study NSW',
      bodyText1:
        'The first runners-up of SAEC 2022 will be awarded a $2000 scholarship each, sponsored by Study New South Wales. Students of the runners-up team will be able to use this scholarship towards the tuition fee for a full-time postgraduate degree program at any NSW education provider.',
      subHeading2: 'About Study NSW',
      bodyText2:
        'Study NSW is a NSW Government agency dedicated to supporting and enriching your international student experience. Our team coordinates a wide range of free initiatives to help international students with living, studying and working in NSW. Study NSW partners with the education sector to deliver four main functions: <br /> 1. Promote NSW’s strengths as a study destination <br /> 2. Enhance the experience of international students in NSW and celebrate their contributions to our communities <br /> 3. Coordinate policy and advocacy to enhance the competitiveness of international education in NSW <br /> 4. Foster international market development opportunities and new technology-enabled models of delivery.',
      learnMoreText: 'Watch Video',
      learnMoreLink: 'https://vimeo.com/701898112',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://www.study.nsw.gov.au/',
    },
    {
      id: 3,
      logo: require('assets/img/prizes/StudyQLD.png'),
      mainHeading: 'Third Prize: $500 for cash voucher',
      subHeading1: 'Sponsored by Study Queensland',
      bodyText1:
        'The second runners-up of SAEC 2022 will be awarded a cash prize of $500 each, sponsored by Study Queensland.',
      subHeading2: 'About Study Queensland',
      bodyText2:
        'Study Queensland is the State Government organisation in Queensland, Australia, dedicated to promoting Queensland as a premier study destination and a preferred partner for education, training, research and innovation.  Whether you are an academic, student or researcher, Study Queensland is your first port of call when choosing where to study or looking for knowledge partners and collaborators. Attracting around 135,000 students from 160 countries, there are many different study locations in Queensland, each with their own outstanding, study offerings, opportunities and experiences.  With a global education network across 15 key Queensland Government Trade and Investment (TIQ) offices around the globe and support from our regional offices, the Study Queensland team also provides on-the-ground support to education and training institutions interested in accessing new opportunities and growing their overseas business development activities and for those looking for opportunities in Queensland.',
      learnMoreText: 'Watch Video',
      learnMoreLink: '',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://www.studyqueensland.qld.gov.au/',
    },
    {
      id: 4,
      logo: require('assets/img/prizes/NSWGovStudyNSW.png'),
      mainHeading: '$1000 per student scholarship for the most innovative idea',
      subHeading1: 'Sponsored by Study NSW',
      bodyText1:
        'The team that presents the most innovative idea will be awarded a $1000 scholarship for each student participant, sponsored by Study New South Wales. Winners of this scholarship would be able to use it towards the tuition fee for a full-time postgraduate degree program at any NSW education provider.',
      subHeading2: 'About Study NSW',
      bodyText2:
        'Study NSW is a NSW Government agency dedicated to supporting and enriching your international student experience. Our team coordinates a wide range of free initiatives to help international students with living, studying and working in NSW. Study NSW partners with the education sector to deliver four main functions: <br /> 1. Promote NSW’s strengths as a study destination <br /> Enhance the experience of international students in NSW and celebrate their contributions to our communities <br /> 3. Coordinate policy and advocacy to enhance the competitiveness of international education in NSW <br /> 4. Foster international market development opportunities and new technology-enabled models of delivery.',
      learnMoreText: 'Watch Video',
      learnMoreLink: 'https://vimeo.com/701898112',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://www.study.nsw.gov.au/',
    },
    {
      id: 5,
      logo: require('assets/img/prizes/BLILogo.jpg'),
      mainHeading: 'Digital Scholarship Certificate',
      subHeading1: 'Sponsored by Blended Learning International',
      bodyText1:
        'All five finalist student teams of SAEC 2022 will be awarded a digital scholarship certificate that they can use to complete one Australian accredited Diploma unit, sponsored by Blended Learning International. Successful completion of this unit will provide the participant with recognition towards an Australian accredited Diploma.',
      subHeading2: 'About Blended Learning International',
      bodyText2:
        'Blended Learning International (BLI) is a “spin-off” company from the Australian National University, where it operated delivering pathway, executive and capstone programs for new entrants, students and staff of the University. It is now an Australian Government Registered Training Organisation (RTO ID 110068) and continues to provide Australian Nationally Accredited Courses, Executive Courses, and Micro-Credentials. BLI has worked with the corporate, community and government sectors to ensure it is industry relevant.  It also provides consulting in the education and training field.',
      learnMoreText: 'Watch Video',
      learnMoreLink: '',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://www.blendedlearning.edu.au/',
    },
    {
      id: 6,
      logo: require('assets/img/prizes/StudyAustralia.png'),
      mainHeading: 'Study Australia Industry Experience Program',
      subHeading1: 'Sponsored by Austrade and Practera',
      bodyText1:
        'All eligible SAEC student participants will be given an opportunity to complete Austrade’s flagship program – <a href="https://practera.com/saiep/#1627017827061-a0fca753-5ac7" target="_blank"> Study Australia Industry Experience Program</a> – delivered in partnership with Practera. SAIEP is a two-week virtual program that brings together Australian international and domestic tertiary student teams to work on real industry projects with Australian and global employers',
      subHeading2: 'About Austrade',
      bodyText2:
        'Australian Trade and Investment Commission (Austrade) is the Australian Government agency that promotes Australian education globally.',
      subHeading3: 'About Practera',
      bodyText3:
        'Practera is the leading platform to power high quality experiential learning programs',
      learnMoreText: 'About Austrade',
      learnMoreLink: 'https://bit.ly/3OVDuon',
      learnMoreText2: 'About Practera',
      learnMoreLink2: 'https://practera.com/saiep/#1627017827061-a0fca753-5ac7',
    },
  ],
  2024: [
    {
      id: 1,
      logos: [
        require('assets/img/prizes2024/BLILogo.jpg'),
        require('assets/img/prizes/StudyAustralia.png'),
      ],
      mainHeading:
        'First Prize: iPads from Austrade & online scholarship towards a Diploma worth AUD$16,000 from Blended Learning International',
      // subHeading1: 'Sponsored by StudyAdelaide',
      bodyText1:
        '<b>Digital Scholarship to do a diploma from Blended Learning International valued at $16,000 per student + iPad from Austrade for each student to continue progressing their innovative business idea.</b> </br> </br> Digital scholarships to complete the Diploma of Business (Business Development) with Blended Learning International.</br></br> <ul><li>The course will be delivered entirely online, including all content, resources, and assessments.</li><li>The course will be enhanced with virtual classroom sessions.</li><li>After completion of the course, participating student/s can use the Diploma to pathway into full bachelor courses with BLI partner universities and avail the program specific credits towards their bachelor course.</li> <li>Students can enrol into the course after completing their Grade 12 with the last intake to happen before 30 June 2027.</li> </ul>iPad from Austrade for each student of the winning team, to continue progressing their innovative business idea.',
      // subHeading2: 'About StudyAdelaide',
      bodyText2:
        'Digital Scholarship to do a diploma from Blended Learning International valued at $16,000 per student + iPad from Austrade for each student to continue progressing their innovative business idea. <br /> <b>Digital scholarships to complete the Diploma of Business (Business Development) with Blended Learning International.</b> The course will be delivered entirely online, including all content, resources, and assessments. The course will be enhanced with virtual classroom sessions. After completion of the course, participating student/s can use the Diploma to pathway into full bachelor courses with BLI partner universities and avail the program specific credits towards their bachelor course. Students can enrol into the course after completing their Grade 12 with the last intake to happen before 30 June 2027. iPad from Austrade for each student of the winning team, to continue progressing their innovative business idea.',
      // learnMoreText: 'Watch Video',
      // learnMoreLink:
      //   'https://www.youtube.com/watch?v=1C7FMaq7unw&feature=youtu.be',
      // learnMoreText2: 'Learn More',
      // learnMoreLink2: 'https://studyadelaide.com/india',
      termsAndCondition: '*Terms & Conditions apply',
    },
    {
      id: 2,
      logos: [
        require('assets/img/prizes2024/Macquarie_University_logo.png'),
        require('assets/img/prizes2024/StudyNSW_Int_Primary.png'),
      ],
      mainHeading:
        'Second Prize: AUD$3,000 scholarship from Study NSW & INR10,000 gift card from Macquarie University',
      // subHeading1: 'Sponsored by Study NSW',
      bodyText1:
        '<b>AUD$3,000 for each student scholarship towards your academic journey in NSW + INR10,000 gift card to assist in your entrepreneurial journey.</b> </br> </br>AUD$3,000 scholarship for each student of the team coming second, given by Study NSW.</br></br> <ul><li>This scholarship can be used by the students towards their tuition fee in a course done in-person in a New South Wales (NSW) education institution.</li><li> Students will have till 30 April 2027 to apply in a NSW education institution to avail the scholarship.</li></ul> INR10,000 gift card to each student of team coming second, to assist in your entrepreneurial journey, given by Macquarie University',
      subHeading2: 'About Study NSW',
      bodyText2:
        'Study NSW is a NSW Government agency dedicated to supporting and enriching your international student experience. Our team coordinates a wide range of free initiatives to help international students with living, studying and working in NSW. Study NSW partners with the education sector to deliver four main functions: <br /> 1. Promote NSW’s strengths as a study destination <br /> 2. Enhance the experience of international students in NSW and celebrate their contributions to our communities <br /> 3. Coordinate policy and advocacy to enhance the competitiveness of international education in NSW <br /> 4. Foster international market development opportunities and new technology-enabled models of delivery.',
      learnMoreText: 'Watch Video',
      learnMoreLink: 'https://vimeo.com/701898112',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://www.study.nsw.gov.au/',
      termsAndCondition: '*Terms & Conditions apply',
    },
    {
      id: 3,
      logos: [require('assets/img/prizes2024/mcm_logo.jpg')],
      mainHeading:
        'Third Prize: AUD$100 cash prize to continue your entrepreneurial journey',
      // subHeading1: 'Sponsored by Study Queensland',
      bodyText1:
        '<b>Cash prize of AUD$100 to each student of the team coming third, to continue your entrepreneurial journey, given by MyCareerMatch.</b>',
      subHeading2: 'About Study Queensland',
      bodyText2:
        'Study Queensland is the State Government organisation in Queensland, Australia, dedicated to promoting Queensland as a premier study destination and a preferred partner for education, training, research and innovation.  Whether you are an academic, student or researcher, Study Queensland is your first port of call when choosing where to study or looking for knowledge partners and collaborators. Attracting around 135,000 students from 160 countries, there are many different study locations in Queensland, each with their own outstanding, study offerings, opportunities and experiences.  With a global education network across 15 key Queensland Government Trade and Investment (TIQ) offices around the globe and support from our regional offices, the Study Queensland team also provides on-the-ground support to education and training institutions interested in accessing new opportunities and growing their overseas business development activities and for those looking for opportunities in Queensland.',
      learnMoreText: 'Watch Video',
      learnMoreLink: '',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://www.studyqueensland.qld.gov.au/',
      termsAndCondition: '*Terms & Conditions apply',
    },
    {
      id: 4,
      logos: [require('assets/img/prizes2024/mcm_logo.jpg')],
      mainHeading:
        'Prize for all eligible schools: Free subscription of My Career Matcher quiz for all eligible schools',
      // subHeading1: 'Sponsored by Study NSW',
      bodyText1:
        '<b>One year subscription to MyCareerMatch quiz for each Indian school qualifying* for SAEC participation, including free online certification on career resources for school counsellors/teachers at the participating schools on MyCareerMatch India website.</b></br> </br><small>*Schools will qualify for this prize when their student teams have submitted their video pitch. Terms & Conditions apply.</small>',
      subHeading2: 'About Study NSW',
      bodyText2:
        'Study NSW is a NSW Government agency dedicated to supporting and enriching your international student experience. Our team coordinates a wide range of free initiatives to help international students with living, studying and working in NSW. Study NSW partners with the education sector to deliver four main functions: <br /> 1. Promote NSW’s strengths as a study destination <br /> Enhance the experience of international students in NSW and celebrate their contributions to our communities <br /> 3. Coordinate policy and advocacy to enhance the competitiveness of international education in NSW <br /> 4. Foster international market development opportunities and new technology-enabled models of delivery.',
      learnMoreText: 'Watch Video',
      learnMoreLink: 'https://vimeo.com/701898112',
      learnMoreText2: 'Learn More',
      learnMoreLink2: 'https://www.study.nsw.gov.au/',
      // termsAndCondition: 'Terms & Conditions apply',
    },
    // {
    //   id: 5,
    //   logo: require('assets/img/prizes/BLILogo.jpg'),
    //   mainHeading: 'Digital Scholarship Certificate',
    //   subHeading1: 'Sponsored by Blended Learning International',
    //   bodyText1:
    //     'All five finalist student teams of SAEC 2022 will be awarded a digital scholarship certificate that they can use to complete one Australian accredited Diploma unit, sponsored by Blended Learning International. Successful completion of this unit will provide the participant with recognition towards an Australian accredited Diploma.',
    //   subHeading2: 'About Blended Learning International',
    //   bodyText2:
    //     'Blended Learning International (BLI) is a “spin-off” company from the Australian National University, where it operated delivering pathway, executive and capstone programs for new entrants, students and staff of the University. It is now an Australian Government Registered Training Organisation (RTO ID 110068) and continues to provide Australian Nationally Accredited Courses, Executive Courses, and Micro-Credentials. BLI has worked with the corporate, community and government sectors to ensure it is industry relevant.  It also provides consulting in the education and training field.',
    //   learnMoreText: 'Watch Video',
    //   learnMoreLink: '',
    //   learnMoreText2: 'Learn More',
    //   learnMoreLink2: 'https://www.blendedlearning.edu.au/',
    // termsAndCondition: true,
    // },
    // {
    //   id: 6,
    //   logo: require('assets/img/prizes/StudyAustralia.png'),
    //   mainHeading: 'Study Australia Industry Experience Program',
    //   subHeading1: 'Sponsored by Austrade and Practera',
    //   bodyText1:
    //     'All eligible SAEC student participants will be given an opportunity to complete Austrade’s flagship program – <a href="https://practera.com/saiep/#1627017827061-a0fca753-5ac7" target="_blank"> Study Australia Industry Experience Program</a> – delivered in partnership with Practera. SAIEP is a two-week virtual program that brings together Australian international and domestic tertiary student teams to work on real industry projects with Australian and global employers',
    //   subHeading2: 'About Austrade',
    //   bodyText2:
    //     'Australian Trade and Investment Commission (Austrade) is the Australian Government agency that promotes Australian education globally.',
    //   subHeading3: 'About Practera',
    //   bodyText3:
    //     'Practera is the leading platform to power high quality experiential learning programs',
    //   learnMoreText: 'About Austrade',
    //   learnMoreLink: 'https://bit.ly/3OVDuon',
    //   learnMoreText2: 'About Practera',
    //   learnMoreLink2: 'https://practera.com/saiep/#1627017827061-a0fca753-5ac7',
    //  termsAndCondition: true,
    // },
  ],
};
