import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// import bg5 from "assets/img/bg5.jpg";
// import blog1 from "assets/img/blog1.jpg";
// import blog2 from "assets/img/blog2.jpg";
// import blog3 from "assets/img/blog3.jpg";

// import blog5 from "assets/img/examples/blog5.jpg";
// import blog6 from "assets/img/examples/blog6.jpg";

import sectionInterestedStyle from 'assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js';
import { BlogCard } from 'components/Blog/BlogCard/BlogCard';
import { blogpostsdata } from '../../BlogPostsData/BlogPostsData';
import './SectionInterested.scss';
const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  const blogs = [];
  // eslint-disable-next-line no-unused-vars
  for (const key in blogpostsdata) {
    blogs.push(blogpostsdata[key]);
  }
  return (
    <div className={classes.section}>
      <h3 className={classes.title + ' ' + classes.textCenter}>
        Hear from some of WA`s brightest students
      </h3>
      <br />
      <GridContainer>
        {blogs.map((blog, index) => (
          <GridItem xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
            <BlogCard
              heading={blog.title}
              description={blog.subheading}
              link={blog.url}
              blogImage={blog.blogImage || blog.backgroundImgUrl}
              backgroundImage={blog.backgroundImgUrl}
            />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
}
