export const VideoLibraryData = {
  2022: [
    {
      id: 1,
      name: 'StudyAdelaide',
      thumbnailVideo:
        'https://www.youtube.com/watch?v=1C7FMaq7unw&feature=youtu.be',
      thumbnailCardImage:
        'https://img.youtube.com/vi/1C7FMaq7unw/maxresdefault.jpg',
      description:
        'StudyAdelaide is a public corporation that grows awareness and preference for Adelaide as a destination for international students across all sectors, including schools, vocational and higher education. StudyAdelaide works in partnership with education providers to deliver a rewarding experience for international students studying in Adelaide',
      videoLink: 'https://www.youtube.com/watch?v=1C7FMaq7unw&feature=youtu.be',
      learnMoreLink: 'https://studyadelaide.com/india',
    },
    {
      id: 2,
      name: 'Study New South Wales',
      thumbnailVideo: 'https://vimeo.com/701898112',
      thumbnailCardImage: 'https://vumbnail.com/701898112.jpg',
      description:
        'Study NSW is part of Investment NSW, a central agency established by the NSW Premier in March 2021, with a mandate to reinforce NSW as the most desirable place in the world to visit, study, invest and do business.',
      videoLink: 'https://vimeo.com/701898112',
      learnMoreLink: 'https://www.study.nsw.gov.au/',
    },
    {
      id: 3,
      name: 'University of Sydney',
      thumbnailVideo:
        'https://www.youtube.com/watch?v=WWcuV8bgHuU&feature=youtu.be',
      thumbnailCardImage:
        'https://img.youtube.com/vi/WWcuV8bgHuU/maxresdefault.jpg',
      description:
        'The University of Sydney is one of the best universities in Australia, and is consistently ranked in the top 50 universities in the world.',
      videoLink: 'https://www.youtube.com/watch?v=WWcuV8bgHuU&feature=youtu.be',
      learnMoreLink: '',
    },
    {
      id: 4,
      name: 'University of Technology Sydney',
      thumbnailVideo: 'https://www.youtube.com/watch?v=nwh-StFZ3Rg&t=77s',
      thumbnailCardImage:
        'https://img.youtube.com/vi/nwh-StFZ3Rg/sddefault.jpg',
      description:
        'The University of Technology Sydney is Australia’s #1 young university, focused on making a difference through leading research, and inspiring education.',
      videoLink: 'https://www.youtube.com/watch?v=nwh-StFZ3Rg&t=77s',
      learnMoreLink: '',
    },
    {
      id: 5,
      name: 'Torrens University Australia',
      thumbnailVideo: 'https://www.youtube.com/watch?v=fvnafBG8wvQ',
      thumbnailCardImage:
        'https://img.youtube.com/vi/fvnafBG8wvQ/sddefault.jpg',
      description:
        'Torrens University is Australia’s leading international university, built on the shoulders of giants.',
      videoLink: 'https://www.youtube.com/watch?v=fvnafBG8wvQ',
      learnMoreLink: '',
    },
  ],
};
