import React from 'react';
// react component for creating beautiful carousel
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent } from '@material-ui/core';
// @material-ui/icons
// core components
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import Muted from 'components/Typography/Muted.js';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import CommonFooter from 'views/CommonFooterPage/CommonFooter';
import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';
import sectionCardsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js';

// import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js';
import { Mentors2024Data } from './MentorsData';

const useStyles = makeStyles(testimonialsStyle);
const useCardStyles = makeStyles(sectionCardsStyle);

export default function MentorPage2024({ ...rest }) {
  const [classicModal, setClassicModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  const years = 2024;
  // const years = possibleYears.includes(rest.match.params.year)
  //   ? rest.match.params.year
  //   : 2024;
  const classes = useStyles();
  const classesCard = useCardStyles();

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: false,
  // };

  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>
      {/* Testimonials 3 START */}

      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>Meet the {years} SAEC mentors</h2>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {Mentors2024Data.map((mentor, index) => (
              <GridItem
                xs={12}
                sm={6}
                md={6}
                key={index}
                // className="custom-grid-card-padding"
              >
                <Card profile plain>
                  <CardAvatar profile plain>
                    <img
                      src={mentor.HEADSHOT}
                      alt={mentor.NAME}
                      className="custom-image-size"
                    />
                  </CardAvatar>
                  <CardBody plain>
                    <h4
                      className={`${classes.title} custom-margin-speaker-name`}
                    >
                      {mentor.NAME}
                    </h4>
                    <Muted>
                      <h6 className="custom-margin-speaker-title">
                        {mentor.POSITION + ' , ' + mentor.ORGANISATION}
                      </h6>
                    </Muted>

                    {/* <h5 className={classes.cardDescription}>
                      {mentor.description}
                    </h5> */}

                    <br />
                    <div
                      className={`${classesCard.stats} ${classesCard.justifyContentCenter}`}
                    >
                      <Button
                        round
                        className="btn-custom-green"
                        onClick={() => {
                          setClassicModal(true);
                          setModalData(mentor);
                        }}
                      >
                        View bio
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={classicModal}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setClassicModal(false);
            setModalData({});
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {modalData && modalData.description === '' ? (
              <p>Description is not available!</p>
            ) : (
              <p>{modalData.BIO}</p>
            )}
          </DialogContent>
        </Dialog>
      </div>

      {/* Testimonials 3 END */}
      <CommonFooter />
    </div>
  );
}
