/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
import PinDrop from '@material-ui/icons/PinDrop';
import Phone from '@material-ui/icons/Phone';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Footer from 'components/Footer/Footer.js';
import city from 'assets/img/ContactUs.jpg';
import CommonFooter from 'views/CommonFooterPage/CommonFooter';

import contactUsStyle from 'assets/jss/material-kit-pro-react/views/contactUsStyle.js';

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.43353, lng: 26.093928 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: 'water',
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: '#0088ff' },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              { hue: '#ff0000' },
              { saturation: -100 },
              { lightness: 99 },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#808080' }, { lightness: 54 }],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ece2d9' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ccdca1' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#767676' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#ffffff' }],
          },
          { featureType: 'poi', stylers: [{ visibility: 'off' }] },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry.fill',
            stylers: [{ visibility: 'on' }, { color: '#b8cb93' }],
          },
          { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
          {
            featureType: 'poi.sports_complex',
            stylers: [{ visibility: 'on' }],
          },
          { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'simplified' }],
          },
        ],
      }}
    >
      <Marker position={{ lat: 44.43353, lng: 26.093928 }} />
    </GoogleMap>
  ))
);

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        // brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="dark" />}
        fixed
        color="dark"
      />
      {/* <div className={classes.bigMap}>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "6px",
                overflow: "hidden"
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div> */}
      <div
        className={`${classes.contacts}`}
        style={{
          backgroundImage: `url(${city})`,
          height: '300px',
        }}
      >
        <div className={classes.container}>
          <GridContainer />
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        {/* <div className={classes.contactContent}> */}
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Get in Touch</h2>
            <GridContainer>
              <GridItem md={6} sm={6}>
                {/* <form>
                    <CustomInput
                      labelText="Your Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Email address"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Phone"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Your message"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6
                      }}
                    />
                    <div className={classes.textCenter}>
                      <Button color="primary" round>
                        Contact us
                      </Button>
                    </div>
                  </form> */}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <div className={classes.container}>
                {/* <GridItem md={12} sm={12}>
                  <InfoArea
                    className={
                      (classes.info, classes.left, classes.inlineBlock)
                    }
                    title="Find us at the office"
                    description={
                      <p>
                        Suite 29/20-40 Meagher St, <br /> Chippendale NSW 2008
                      </p>
                    }
                    icon={PinDrop}
                    iconColor="green"
                    justifyContent="500px"
                  />
                </GridItem> */}

                <GridItem>
                  <InfoArea
                    className={(classes.left, classes.inlineBlock)}
                    title="Give us a ring"
                    description={
                      <p>
                        1300 953 133 <br /> Mon - Fri, 9:00am-5:00pm
                      </p>
                    }
                    icon={Phone}
                    iconColor="green"
                  />
                </GridItem>

                <GridItem>
                  <InfoArea
                    className={(classes.left, classes.inlineBlock)}
                    title="Email us"
                    description={<p>events@frankteam.com.au</p>}
                    icon={BusinessCenter}
                    iconColor="green"
                  />
                </GridItem>
              </div>
            </GridContainer>
          </div>
        </div>
        {/* </div> */}
      </div>
      <CommonFooter />
    </div>
  );
}
