import React from 'react';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import CardAvatar from 'components/Card/CardAvatar';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Muted from 'components/Typography/Muted';
import CommonFooter from 'views/CommonFooterPage/CommonFooter';
import { AcademicSpeakerData } from './AcademicSpeakerData';
import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';
import sectionCardsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js';

const useStyles = makeStyles(testimonialsStyle);
const useCardStyles = makeStyles(sectionCardsStyle);

export default function AcademicSpeakerPage({ ...rest }) {
  const [classicModal, setClassicModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const possibleYears = ['2020', '2021', '2022'];
  const years = possibleYears.includes(rest.match.params.year)
    ? rest.match.params.year
    : 2020;
  const classes = useStyles();
  const classesCard = useCardStyles();

  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>
      {/* Testimonials 3 START */}
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={7}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Meet the {years} SAEC academic spotlight speakers
              </h2>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {AcademicSpeakerData[years].map((mentor, index) => (
              <GridItem
                xs={12}
                sm={6}
                md={6}
                key={index}
                // className="custom-grid-card-padding"
              >
                <Card profile plain>
                  <CardAvatar profile plain>
                    <img src={mentor.imageURL} alt={mentor.name} />
                  </CardAvatar>
                  <CardBody plain>
                    <h4
                      className={`${classes.title} custom-margin-speaker-name`}
                    >
                      {mentor.name}
                    </h4>
                    <Muted>
                      <h6 className="custom-margin-speaker-title">
                        {mentor.mutedText}
                      </h6>
                    </Muted>

                    {/* <h5 className={classes.cardDescription}>
                      {mentor.description}
                    </h5> */}

                    <br />
                    <div
                      className={`${classesCard.stats} ${classesCard.justifyContentCenter}`}
                    >
                      <Button
                        round
                        className="btn-custom-green"
                        onClick={() => {
                          setClassicModal(true);
                          setModalData(mentor);
                        }}
                      >
                        View bio
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>

        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={classicModal}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setClassicModal(false);
            setModalData({});
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {modalData && modalData.description === '' ? (
              <p>Description is not available!</p>
            ) : (
              <p>{modalData.description}</p>
            )}
          </DialogContent>
        </Dialog>
      </div>
      {/* Testimonials 3 END */}
      <CommonFooter />
    </div>
  );
}
