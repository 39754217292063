import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardAvatar from 'components/Card/CardAvatar';
import Muted from 'components/Typography/Muted.js';
import Header from 'components/Header/Header';
import Button from 'components/CustomButtons/Button.js';
import HeaderLinks from 'components/Header/HeaderLinks';
import TeamsData from '../TeamsPage/TeamsData';
import CommonFooter from 'views/CommonFooterPage/CommonFooter';
import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';
import sectionCardsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js';

const useStyles = makeStyles(teamsStyle);
const useCardStyles = makeStyles(sectionCardsStyle);

export default function FrankedioFacilitators({ ...rest }) {
  const classes = useStyles();
  const classesCard = useCardStyles();

  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>

      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={12}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>Previous SAEC Teams</h2>
              <h5 className={classes.descriptionWhite}>
                These teams came together to solve real-world global challenges.
              </h5>
            </GridItem>
          </GridContainer>

          <GridContainer className={'d-flex justify-content-center'}>
            {TeamsData.map((mentor, index) => (
              <GridItem xs={12} sm={3} md={4} key={index}>
                <Card profile plain>
                  <CardAvatar
                    profile
                    plain
                    style={{
                      height: '120px',
                    }}
                  >
                    <img
                      src={mentor.imageURL}
                      alt={mentor.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </CardAvatar>

                  <CardBody plain>
                    <h4
                      className={`${classes.title} custom-margin-speaker-name`}
                    >
                      {mentor.place}
                    </h4>

                    <Muted>
                      <h6 className="custom-margin-speaker-title">
                        Team {mentor.teamName} - {mentor.theme}
                      </h6>
                    </Muted>

                    <br />
                    <div
                      className={`${classesCard.stats} ${classesCard.justifyContentCenter}`}
                    >
                      <Button
                        round
                        className="btn-custom-green"
                        onClick={() => {
                          window.open(mentor.youtube, '_newTab');
                        }}
                      >
                        VIEW VIDEO
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
}

// import React from 'react';

// // @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
// import Header from 'components/Header/Header.js';
// import HeaderLinks from 'components/Header/HeaderLinks.js';
// import PropTypes from 'prop-types';

// // core components
// import GridContainer from 'components/Grid/GridContainer.js';
// import GridItem from 'components/Grid/GridItem.js';
// import b01 from 'assets/img/b01.jpg';

// import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';
// import { TeamCard } from 'components/TeamCard/TeamCard';

// const useStyles = makeStyles(teamsStyle);

// export default function TeamsPage({ teams }) {
//   React.useEffect(() => {
//     window.scrollTo(0, 0);
//     document.body.scrollTop = 0;
//   });
//   const classes = useStyles();
//   return (
//     <div>
//       <Header
//         // brand="Material Kit PRO React"
//         links={<HeaderLinks dropdownHoverColor="info" />}
//         fixed
//         color="transparent"
//         changeColorOnScroll={{
//           height: 300,
//           color: 'info',
//         }}
//       />

//       <div className="cd-section">
//         <div
//           className={classes.team + ' ' + classes.section}
//           style={{ backgroundImage: `url(${b01})` }}
//         >
//           <div className={classes.container}>
//             <GridContainer>
//               <GridItem
//                 xs={12}
//                 sm={8}
//                 md={8}
//                 className={
//                   classes.mlAuto +
//                   ' ' +
//                   classes.mrAuto +
//                   ' ' +
//                   classes.textCenter
//                 }
//               >
//                 {/* <h2 className={classes.titleWhite}>2024 SAEC Teams</h2>
//                 <h3 className={classes.titleWhite}>
//                   COMING SOON
//                 </h3> */}
//                 <h2 className={classes.titleWhite}>2022 SAEC Teams</h2>
//                 <h5 className={classes.descriptionWhite}>
//                   Meet our amazing SAEC 2022 teams. These teams came together to
//                   solve real-world global challenges.
//                 </h5>
//               </GridItem>
//             </GridContainer>
//             <GridContainer>
//               {teams.map((team, index) => (
//                 <GridItem xs={12} sm={4} md={4} key={index}>
//                   <TeamCard
//                     id={team.id}
//                     name={team.name}
//                     image={team.image}
//                     motto={team.motto}
//                     url={team.url}
//                   />
//                 </GridItem>
//               ))}
//             </GridContainer>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// TeamsPage.propTypes = {
//   title: PropTypes.string.isRequired,
//   teams: PropTypes.array.isRequired,
// };
