import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Muted from 'components/Typography/Muted.js';
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import { FrankedioFacilitatorsData } from './FrankedioFacilitatorsData';
import CardAvatar from 'components/Card/CardAvatar';
import CommonFooter from 'views/CommonFooterPage/CommonFooter';
import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';
import { Speakers2024Data } from 'views/MentorPage/MentorsData';

const useStyles = makeStyles(teamsStyle);

export default function FrankedioFacilitators({ ...rest }) {
  const classes = useStyles();

  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>

      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={12}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Franked.io expert facilitation team
              </h2>
              {/* <h5 className={classes.description}>
                Facilitators from the business, start-up and community sectors
                will deliver this intense 4 day challenge
              </h5> */}
            </GridItem>
          </GridContainer>

          <GridContainer className={'d-flex justify-content-center'}>
            {FrankedioFacilitatorsData.map((mentor, index) => (
              <GridItem
                xs={12}
                sm={3}
                md={3}
                key={index}

                // className="custom-grid-card-padding"
              >
                <Card profile plain>
                  <CardAvatar profile plain>
                    <img src={mentor.imageURL} alt={mentor.name} />
                  </CardAvatar>
                  <CardBody plain>
                    <h4
                      className={`${classes.title} custom-margin-speaker-name`}
                    >
                      {mentor.name}
                    </h4>
                    <Muted>
                      <h6 className="custom-margin-speaker-title">
                        {mentor.mutedText}
                      </h6>
                    </Muted>

                    {/* <h5 className={classes.cardDescription}>
                      {mentor.description}
                    </h5> */}

                    {/* <br />
                    <Button
                      round
                      className="btn-custom-green"
                      onClick={() => {
                        setClassicModal(true);
                        setModalData(mentor);
                      }}
                    >
                      View bio
                    </Button> */}
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </GridContainer>

          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={12}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>2024 SAEC speakers</h2>
              {/* <h2 className={classes.title}>2024 SAEC speakers coming soon</h2> */}
              {/* <h5 className={classes.description}>
                Facilitators from the business, start-up and community sectors
                will deliver this intense 4 day challenge
              </h5> */}
            </GridItem>
          </GridContainer>
          <GridContainer>
            {Speakers2024Data.map((mentor, index) => (
              <GridItem
                xs={12}
                sm={3}
                md={3}
                key={index}

                // className="custom-grid-card-padding"
              >
                <Card profile plain>
                  <CardAvatar profile plain>
                    <img
                      src={mentor.HEADSHOT}
                      alt={mentor.NAME}
                      className="custom-image-size"
                      // style={{
                      //   objectFit: 'cover',
                      //   height: '130px',
                      //   width: '130px',
                      //   objectPosition: 'top',
                      // }}
                    />
                  </CardAvatar>
                  <CardBody plain>
                    <h4
                      className={`${classes.title} custom-margin-speaker-name`}
                    >
                      {mentor.NAME}
                    </h4>
                    <Muted>
                      <h6 className="custom-margin-speaker-title">
                        {mentor.POSITION + ', ' + mentor.ORGANISATION}
                        {/* {mentor.mutedText} */}
                      </h6>
                    </Muted>

                    {/* <h5 className={classes.cardDescription}>
                      {mentor.description}
                    </h5> */}

                    {/* <br />
                    <Button
                      round
                      className="btn-custom-green"
                      onClick={() => {
                        setClassicModal(true);
                        setModalData(mentor);
                      }}
                    >
                      View bio
                    </Button> */}
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
}
