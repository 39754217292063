import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
// import CardAvatar from 'components/Card/CardAvatar.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
// import Button from 'components/CustomButtons/Button.js';
import Muted from 'components/Typography/Muted.js';

import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';

// import bg7 from 'assets/img/bg7.jpg';
// import bg0 from 'assets/img/bg0.jpg';
// import city from 'assets/img/examples/city.jpg';
// import Facilitatornatasha from 'assets/img/faces/Facilitatornatasha.jpeg';
import Facilitatoraaron from 'assets/img/faces/Facilitatoraaron.jpeg';
import Facilitatorsisabelle from 'assets/img/faces/Facilitatorsisabelle.jpeg';
import Facilitatorsdicey from 'assets/img/faces/Facilitatorsdicey.jpeg';
// import Facilitatorskali from 'assets/img/faces/Facilitatorskali.jpeg';

// import marc from 'assets/img/faces/marc.jpg';
// import christian from 'assets/img/faces/christian.jpg';

// import kendall from 'assets/img/faces/kendall.jpg';
// import avatar from 'assets/img/faces/avatar.jpg';
// import cardProfile1 from 'assets/img/examples/card-profile1.jpg';
// import cardProfile2 from 'assets/img/examples/card-profile2.jpg';
// import cardProfile4 from 'assets/img/examples/card-profile4.jpg';
// import cardProfile1Square from 'assets/img/faces/card-profile1-square.jpg';
// import cardProfile2Square from 'assets/img/faces/card-profile2-square.jpg';
// import cardProfile4Square from 'assets/img/faces/card-profile4-square.jpg';
// import cardProfile6Square from 'assets/img/faces/card-profile6-square.jpg';

const useStyles = makeStyles(teamsStyle);

export default function SectionFacilitators({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>Our Expert Facilitation Team</h2>
              <h5 className={classes.description}>
                Facilitators from the business, start-up and community sectors
                will deliver this intense 4 day challenge
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {/* <GridItem xs={6} sm={3} md={3}>
              <Card plain profile>
                <CardHeader image plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={Facilitatornatasha} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${Facilitatornatasha})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>Natasha Munasinghe</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CEO, franked.io</h6>
                  </Muted>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}> */}
            {/* <Button justIcon round color="linkedin">
                    <i className="fab fa-linkedin-in" />
                  </Button> */}
            {/* <Button justIcon round color="facebook">
                    <i className="fab fa-facebook-square" />
                  </Button>
                  <Button justIcon round color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button> */}
            {/* </CardFooter>
              </Card>
            </GridItem> */}
            <GridItem xs={12} sm={4} md={4}>
              <Card plain profile>
                <CardHeader image plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={Facilitatoraaron} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${Facilitatoraaron})`,
                      opacity: '1',
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>Aaron Ngan</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Presenter, franked.io & CEO Junior Achievement Australia
                    </h6>
                  </Muted>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}>
                  {/* <Button justIcon round color="linkedin">
                    <i className="fab fa-linkedin-in" />
                  </Button> */}
                  {/* <Button justIcon round color="facebook">
                    <i className="fab fa-facebook-square" />
                  </Button>
                  <Button justIcon round color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain profile>
                <CardHeader image plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={Facilitatorsisabelle} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${Facilitatorsisabelle})`,
                      opacity: '1',
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>Isabelle Goldfarb</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Impact Programs Manager, Spacecubed
                    </h6>
                  </Muted>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}>
                  {/* <Button justIcon round color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button>
                  <Button justIcon round color="twitter">
                    <i className="fab fa-twitter" />
                  </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain profile>
                <CardHeader image plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={Facilitatorsdicey} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${Facilitatorsdicey})`,
                      opacity: '1',
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>Dicey Bergic</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Community, Spacecubed
                    </h6>
                  </Muted>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}>
                  {/* <Button justIcon round color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button>
                  <Button justIcon round color="twitter">
                    <i className="fab fa-twitter" />
                  </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
