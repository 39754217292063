import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
// import CardFooter from 'components/Card/CardFooter.js';
// import Button from 'components/CustomButtons/Button.js';
import Muted from 'components/Typography/Muted.js';
// import ScrollAnimation from 'react-animate-on-scroll';

import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';

import SpeakerDaryl from 'assets/img/faces/SpeakerDaryl.jpeg';
// import Speakerglenn from 'assets/img/faces/Speakerglenn.jpeg';
// import Speakerlucas from 'assets/img/faces/Speakerlucas.jpeg';
// import Speakerchibs from 'assets/img/faces/Speakerchibs.jpeg';
// import SpeakerTom from 'assets/img/faces/SpeakerTom.jpeg';
// import MentorKim from 'assets/img/faces/MentorKim.jpeg';
// import ComingSoon from 'assets/img/faces/ComingSoon.png';

// import presentationiPadComments from 'assets/img/assets-for-demo/presentationViewSectionComponent/ipad-comments.jpg';
// import presentationiPadTable from 'assets/img/assets-for-demo/presentationViewSectionComponent/ipad-table.jpg';
// import ipad from 'assets/img/assets-for-demo/presentationViewSectionComponent/ipad.png';
// import SectionTab from './SectionTab';

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>Keynote speakers</h2>
              {/* <h5 className={classes.description}>
                Meet the amazing WA and Australian speakers, entrepreneurs and
                leaders that will be joining us for the Joondalup Innovation
                Challenge
              </h5> */}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={SpeakerDaryl} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Yvonne Power</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>
                      Co-Founder & COO at Village Energy, 40under40 winner,
                      Chair GGWA
                    </h6>
                  </Muted>
                  <p className={classes.description}>
                    Yvonne Power is passionate about building a sustainable
                    energy future, especially in communities and developing
                    nations. Yvonne is co-founder and Chief Operations Officer
                    of Village Energy, a WA born start-up that has developed
                    energy technology to operate at the edge of the energy grid
                    and deliver cleaner, cheaper and more reliable power to
                    underserved populations. Yvonne led the development of a
                    highly successful pilot project in Andhra Pradesh, India,
                    with which WA has a sister-state relationship. Since the
                    pilot, Village Energy has signed agreements across India,
                    Indonesia, Vietnam and Cambodia and has a potential pipeline
                    to millions of customer connections. Village Energy is
                    backed by a Bill Gates chaired venture capital fund,
                    Breakthrough Energy Ventures, and has been recognized as an
                    APAC25 company and is the 2020 winner of the Australian
                    Technologies Competition in energy. Yvonne is a mother of
                    three, 2020 40 under 40 award winner, and formerly worked at
                    Horizon Power as Manager Strategy, helping to drive the
                    shift to renewable and distributed energy microgrids.
                  </p>
                </CardBody>

                {/* <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/melissa-strutt/"
                    target="blank"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin" /> 
                  </Button> 
                   <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="facebook"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  > 
                  <i className="fab fa-facebook" />
                  </Button>
                  <Button
                    href="#pablo"
                    justIcon
                    simple
                    color="google"
                    className={classes.btn}
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-google" />
                  </Button> 
                </CardFooter> */}
              </Card>
            </GridItem>

            {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#" onClick={e => e.preventDefault()}>
                    <img src={SpeakerDaryl} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>TBC</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>TBC</h6>
                  </Muted>
                  <p className={classes.description}>TBC</p>
                </CardBody>
              </Card>
            </GridItem> */}
            {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    <img src={Speakerchibs} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Chibs Okereke Murray</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Meditation & Resilience Specialist</h6>
                  </Muted>
                  <p className={classes.description}>
                    Chibs has gone from being a CEO of a very successful
                    healthcare company to now being a Mindfulness Coach. He will
                    support and enable students to perform at their best by
                    kicking off each session with a dose of mindfulness and
                    balance.
                  </p>
                </CardBody>
              </Card>
            </GridItem> 
            <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={MentorKim} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Kim Smith</h4>
                  <Muted>
                  <h6 className={classes.cardCategory}>FOUNDING DIRECTOR & CEO @ STANDING STRONG INTERNATIONAL</h6>
                  </Muted>
                  <p className={classes.description}>
                  {''}Kim Smith is the founder of Standing Strong Clubs, an international health and wellbeing organisation providing preventative physical and mental health and wellbeing programs for children and teens.{''}                    
                  {''}In 2020, Kim was awarded the Telstra Business Womens’ Award for Purpose & Social Enterprise WA and was the 2021 winner of the Business News 40Under40 Award for Community & Social Enterprise. Kim and Standing Strong Clubs have been endorsed by Oprah Winfrey and have been featured on The Today Show, Daily Telegraph, ABC Radio, The Western Times, Girlfriend Magazine, Dolly Magazine and many more media outlets.{''}
                  </p>
                </CardBody>
              </Card>
            </GridItem>  */}
            {/* <GridItem xs={12} sm={6} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <a href="" onClick={(e) => e.preventDefault()}>
                    <img src={SpeakerTom} alt="..." className={classes.img} />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Tom Young</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CEO @ udrew</h6>
                  </Muted>
                  <p className={classes.description}>
                    Tom has worked in the building industry in Australia and Asia, specializing in: Geo-engineering investigations, Soil mechanics, Geo-technical Laboratory's & analysis, Structural Drafting & design, Modular Fabrication, Architecture, System designer, Software development and expert kebab advisor / mentor.
                  </p>
                </CardBody>
              </Card>
            </GridItem>  */}
          </GridContainer>
          {/* <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h5 className={classes.description}>Announcing soon</h5>
            </GridItem>
          </GridContainer> */}
          {/* <SectionTab /> */}
          {/* <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Technology Enabled Solutions</h3>
              <h6 className={classes.description}>
                Entrepreneurship is A Mindset
              </h6>
              <h5 className={classes.description}>
                Student ingenuity and creativity will be unleashed to solve
                real-world problems that are responsive, tested and technology
                enabled.
              </h5>
            </div>
          </GridItem>
          <GridItem md={7} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <div className={classes.animeAreaImg}>
                <ScrollAnimation animateIn="fadeInUp">
                  <img
                    src={presentationiPadComments}
                    alt="iPad comments"
                    className={classes.areaImg}
                  />
                </ScrollAnimation>
              </div>
              <div className={classes.animeInfoImg}>
                <ScrollAnimation animateIn="fadeInUp">
                  <img
                    src={presentationiPadTable}
                    alt="iPad table"
                    className={classes.infoImg}
                  />
                </ScrollAnimation>
              </div>
              <img className={classes.ipadImg} src={ipad} alt="iPad" />
            </div>
          </GridItem>
        </GridContainer> */}
        </div>
      </div>
    </div>
  );
}
