export const EntrepreneurshipData = {
  2022: [
    {
      id: 1,
      name: 'Dr Jo Hawkins',
      imageURL: require('assets/img/faces/Entrepreneurs/EntrDrJoHawkins.jpeg'),
      mutedText: 'Innovation Manager, University of Western Australia',
      description:
        'Dr. Hawkins champions and facilitates industry engagement and innovation to enhance research impact and the student experience. She develops, manages and evaluates a portfolio of prototype projects under tight deadlines and budgets, utilising methodologies including lean start-up and design thinking. She employs a strategic approach to design and implement strategic initiatives to support cultural change. Dr. Hawkins has strong competencies in communication, stakeholder management, training, mentoring and facilitation.',
    },
    {
      id: 2,
      name: 'Murray Hurps',
      imageURL: require('assets/img/faces/Entrepreneurs/EntrMurrayHurps.png'),
      mutedText:
        'Director of Entrepreneurship, University of Technology Sydney',
      description:
        'Murray Hurps is UTS’ first Director of Entrepreneurship, tasked with growing the number of student-founded startups coming out of UTS and nurturing the Ultimo precinct’s vibrant startup environment. Murray plays a key role in developing and sustaining pathways connecting UTS graduates and student founders to the startup ecosystem locally, regionally, and internationally, including commercial accelerators, venture capital and international destinations. Murray also leads initiatives to ensure Ultimo continues to expand its historical position as a significant contributor to Sydney’s startup ecosystem.',
    },
    {
      id: 3,
      name: 'Nimrod Klayman',
      imageURL: require('assets/img/faces/Entrepreneurs/EntrNimrodKlayman.jpeg'),
      mutedText: 'Head of Entrepreneurship, University of Queensland',
      description:
        'Nimrod Klayman has more than 20 years experience operating in leading roles within different sectors, with experience working in start-ups and within large corporations based in Australia, Israel, and Argentina. Besides, Nimrod is a serial founder passionate about building new ventures and finding new opportunities. Nimrod currently leads the University of Queensland’s (Top 50 world-class university) Entrepreneurship programs UQ Ventures. UQ Ventures provides a home for entrepreneurs and Innovators that equips aspiring student entrepreneurs with the requisite skills and knowledge needed to conceive a start-up. Nimrod, through his extended global connections, created and leads the ""Global Startup Adventure"", a unique overseas innovation and entrepreneurship experience, based at some of the hottest global centres for entrepreneurship and innovation in Shanghai, Shenzhen, Tel Aviv, San Francisco and Singapore.',
    },
  ],
};
