export const FrankedioFacilitatorsData = [
  {
    id: 1,
    name: 'Aaron Ngan',
    imageURL: require('assets/img/faces/Facilitatoraaron.jpeg'),
    mutedText: 'Presentation Coach and Facilitator',
    description: '',
  },
  {
    id: 2,
    name: 'Jasmin Ward',
    imageURL: require('assets/img/faces/Facilitatorsjasmin.jpg'),
    mutedText: 'Innovation & Strategy Facilitator',
    description: '',
  },
  // {
  //   id: 3,
  //   name: 'Dicey Bergic',
  //   imageURL: require('assets/img/faces/Facilitatorsdicey.jpeg'),
  //   mutedText: 'Presenter and Facilitator',
  //   description: '',
  // },
  {
    id: 4,
    name: 'Amie Morris',
    imageURL: require('assets/img/faces/FacilitatorAmieMorris.jpg'),
    mutedText: 'Presenter and Head of Media at Bondi Radio Media Group',
    description: '',
  },
  // {
  //   id: 5,
  //   name: 'Amie Morris',
  //   imageURL: require('assets/img/teamProfiles/Econominds.png'),
  //   mutedText: 'Presenter and Head of Media at Bondi Radio Media Group',
  //   description: '',
  // },
];
