import {
  title,
  description,
  mrAuto,
  mlAuto,
} from 'assets/jss/material-kit-pro-react.js';

const servicesStyle = {
  title,
  mrAuto,
  mlAuto,
  services: {
    paddingTop: '10px',
    paddingBottom: '80px',
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  description,
};

export default servicesStyle;
