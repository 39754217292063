import React from 'react';
// import { Link } from "react-router-dom";

// react component for creating beautiful carousel
// import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import FormatQuote from "@material-ui/icons/FormatQuote";
// import Star from "@material-ui/icons/Star";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';
import CommonFooter from 'views/CommonFooterPage/CommonFooter';

const useStyles = makeStyles(testimonialsStyle);

export default function Mentors2022Page({ ...rest }) {
  const classes = useStyles();
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: false,
  // };
  return (
    <div className="cd-section" {...rest}>
      <div>
        <Header
          // brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
          changeColorOnScroll={{
            height: 300,
            color: 'info',
          }}
        />
      </div>
      {/* Testimonials 3 START */}
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
              }
            >
              <h2 className={classes.title}>Announcing Mentors Shortly</h2>
            </GridItem>
          </GridContainer>
          {/* <GridContainer> */}
          {/* <GridItem xs={12} sm={4} md={4}> */}
          {/* <Card testimonial plain>
                <CardAvatar testimonial plain> */}
          {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
          {/* <img src={MentorDawn} alt="..." /> */}
          {/* </a> */}
          {/* </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Dawn McAleenan</h4>
                  <Muted>
                    <h6>CEO @ ESSENTIAL PERSONNEL</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}With over eighteen years of experience within the not for profit sector, Dawn has managed a range of services at a national, regional and local community levels across the UK and Western Australia. 
                    Dawn’s interest has always been in reducing the gap of social exclusion for vulnerable clients through targeted provision and community integration.{''}
                  </h5>
                  <h5 className={classes.cardDescription}>
                    {''}Dawn was also awarded as a 2021 Business News 40 Under 40 Winner. The 40 Under 40 Awards recognise and celebrate the states leading entrepreneurs, innovators and future business leaders under the age of 40.{''}
                  </h5>
                </CardBody>
              </Card> */}
          {/* </GridItem> */}
          {/* <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain> */}
          {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
          {/* <img src={MentorJiaranai} alt="..." /> */}
          {/* </a> */}
          {/* </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Jiaranai Keatnuxsuo</h4>
                  <Muted>
                    <h6>TECHNICAL ACCOUNT MANAGER @ MICROSOFT</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Jia is a thriving Tech Optimiser, a STEM Advocate, a TEDx Speaker, a multi-awards winner of Women in Technology Western Australia 2019, NASA Space App Challenge, Energy Hack, Transport Hack WA and UWA Innovation Challenge. Jia works at Microsoft.{''}
                    </h5>
                    <h5 className={classes.cardDescription}>
                    {''}She is a Winner of Women in Technology Western Australia 2019. You often find her involved in community events that increase technology accessibility and inclusiveness to the underrepresented groups such as encouraging other women to come into tech and inspiring disadvantaged students to be curious and confident in the world of STEM.{''}</h5>
                </CardBody>
            </Card>
            </GridItem>              */}
          {/* <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain> */}
          {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
          {/* <img src={MentorYvonne} alt="..." /> */}
          {/* </a> */}
          {/* </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Yvonne Power</h4>
                  <Muted>
                    <h6>COFOUNDER & COO @ VILLAGE ENERGY</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Yvonne is passionate about building a sustainable energy future, especially in communities and developing nations. Yvonne is co-founder and Chief Operations Officer of Village Energy, a WA born start-up that has developed energy technology to operate at the edge of the energy grid and deliver cleaner, cheaper and more reliable power to underserved populations.Yvonne led the development of a highly successful pilot project in Andhra Pradesh, India, with which WA has a sister-state relationship.Since the pilot, Village Energy has signed agreements across India, Indonesia, Vietnam and Cambodia and has a potential pipeline to millions of customer connections.{''}</h5>
                  <h5 className={classes.cardDescription}>
                    {''}Village Energy is backed by a Bill Gates chaired venture capital fund, Breakthrough Energy Ventures, and has been recognized as an APAC25 company and is the 2020 winner of the Australian Technologies Competition in energy.Yvonne is a mother of three, 2020 40 under 40 award winner, and formerly worked at Horizon Power as Manager Strategy, helping to drive the shift to renewable and distributed energy microgrids.{''}</h5>
                    </CardBody>
              </Card>
            </GridItem>       */}
          {/* <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain> */}
          {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
          {/* <img src={MentorJacqui} alt="..." /> */}
          {/* </a> */}
          {/* </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Jacqui Nelson</h4>
                  <Muted>
                    <h6>CEO @ DEKKO SECURE</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                    {''}Jacqui is the CEO of Dekko Secure, an Australian cyber security company that helps organisations to share, collaborate and communicate with total privacy and security. She has a passion for innovation and success and making a positive contribution to business and society. Jacqui has been closely involved in the start up and successful growth of several commercial and social enterprise ventures.                  
                   </h5>
                </CardBody>
                </Card>
            </GridItem>     */}
          {/* <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain> */}
          {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
          {/* <img src={MentorEunice} alt="..." /> */}
          {/* </a> */}
          {/* </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>Dr Eunice Sari</h4>
                  <Muted>
                    <h6>CEO & CO-FOUNDER @ UX INDONESIA</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>
                  Dr. Eunice Sari is the CEO and Co-Founder of UX Indonesia, the first insight-driven UX Research, Training, and Consulting Company based in Indonesia since 2002, and co-founder of Customer Experience Insight Pty Ltd (Australia). Having more than 15 years of experience in academics and industries, she has helped many. She is also the first Asian female Google Global elite mentor in Product Design and Strategy (UX, UI)  helping startups around the world, particularly from emerging nations in Southeast Asia, Africa, Eastern Europe and Latin America.                 
                  </h5>                
                </CardBody>
                </Card>
            </GridItem> */}
          {/* <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardAvatar testimonial plain> */}
          {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
          {/* <img src={MentorGeorge} alt="..." /> */}
          {/* </a> */}
          {/* </CardAvatar>
                <CardBody plain>
                  <h4 className={classes.title}>George Mavros</h4>
                  <Muted>
                    <h6>BUSINESS COACH @ ETSI CONSULTING</h6>
                  </Muted>
                  <h5 className={classes.cardDescription}>   
                  </h5>                
                </CardBody>
                </Card>
            </GridItem> */}
          {/* </GridContainer> */}
        </div>
      </div>
      {/* Testimonials 3 END */}
      <CommonFooter />
    </div>
  );
}
