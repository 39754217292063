import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// @material-ui icons

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import overviewStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js';

const useStyles = makeStyles(overviewStyle);

export default function SectionOverview() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      {/* <div
        className={classes.features5}
        style={{
          backgroundImage: `url(${require("assets/img/assets-for-demo/features-5.jpg")})`,
        }}
      >
        <GridItem md={8} className={classNames(classes.mlAuto, classes.mrAuto)}>
          <h2 className={classes.title}>Our 2 Week Supercharged Journey</h2>
        </GridItem>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 1: Virtual Orientation Day"
                description={
                  <p>
                    Teams from over 20 institutions meet each other and their mentors. Teams
                    participate in social team building challenges using social
                    media and team communication platforms.
                  </p>
                }
                icon={Grid}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 2: Virtual Summit 1"
                description={
                  <p>
                    Virtual Summit #1 kicks off with 250 students. It is high
                    energy and engagement with both education and entertainment.
                    An inspiring keynote session is delivered by a wellknown Australian
                    entreprenuer. Students are introduced to key
                    entrepreneurship and start up methodologies.
                  </p>
                }
                icon={PhoneLink}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 3: Virtual Summit 2"
                description={
                  <p>
                    Virtual Summit #2 kicks off with another inspiring keynote
                    speaker. Students learn how to wireframe and test their
                    ideas. Teams finalise their business ideas and get ready for
                    their pitches. Mentors are on stand-by to help the teams.
                  </p>
                }
                icon={Adb}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Day 4: Hybrid Pitch Night"
                description={
                  <p>
                    This is the Hybrid Pitch Night infront of 3 esteemed judges
                    and a captive audience. Each shortlisted team will pitch to
                    the judges and 3 winning teams will be announced. Participants can choose to attend in-person or via livestream.
                  </p>
                }
                icon={AttachMoney}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
            <GridItem sm={4} className={classes.featuresShow}>
              <InfoArea
                title="Guest Masterclasses"
                description={
                  <p>
                    Students have access to guest masterclasses through this challenge to upskill and finetune their learning.
                  </p>
                }
                icon={AccessTime}
                iconColor="gray"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div> */}
      <div className={classes.sectionTestimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <h2 className={classes.title}>Our Partners</h2>
              <h5 className={classes.description}>
                With support from the Australian Government, Australian
                educational institutions, Indian education institutions and
                industry.
                {/* <b> 1500 students</b>{" "} */}
              </h5>
            </GridItem>
          </GridContainer>

          {/* <GridContainer>
            <GridItem md={4} sm={4}>
              <Card plain profile>
                <GridContainer>
                  <GridItem md={3} sm={3}> */}
          {/* <CardHeader image plain>
                      <img src={people} alt="..." />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people + ")",
                          opacity: "1"
                        }}
                      />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people + ")",
                          opacity: "1",
                        }}
                      />
                    </CardHeader> */}
          {/* </GridItem>
                  <GridItem md={9} sm={9}> */}
          {/* <CardBody plain className={classes.alignLeft}>
                      <h4 className={classes.cardTitle}>
                        INTERCHANGE 2019 Participant
                      </h4>
                      <p className={classes.cardDescription}>
                        {'"'}This event is AMAZEBALLS!{"'"}
                      </p>
                    </CardBody> */}
          {/* </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem md={4} sm={4}>
              <Card plain profile>
                <GridContainer>
                  <GridItem md={3} sm={3}> */}
          {/* <CardHeader image plain>
                      <img src={people2} alt="..." />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people2 + ")",
                          opacity: "1",
                        }}
                      />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people2 + ")",
                          opacity: "1",
                        }}
                      />
                    </CardHeader> */}
          {/* </GridItem>
                </GridContainer>
              </Card>
            </GridItem>{" "}
            <GridItem md={4} sm={4}>
              <Card plain profile> */}
          {/* <GridContainer> */}
          {/* <GridItem md={3} sm={3}>
                    <CardHeader image plain>
                      <img src={people1} alt="..." />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people1 + ")",
                          opacity: "1",
                        }}
                      />
                      <div
                        // className={classes.coloredShadow}
                        style={{
                          backgroundImage: "url(" + people1 + ")",
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem> */}
          {/* <GridItem md={9} sm={9}> */}
          {/* <CardBody plain className={classes.alignLeft}>
                      <h4 className={classes.cardTitle}>
                        INTERCHANGE 2019 Participant
                      </h4>
                      <p className={classes.cardDescription}>
                        {'"'}It is a great platform to network and get to learn
                        about the work culture of Australia{'"'}
                      </p>
                    </CardBody> */}
          {/* </GridItem> */}
          {/* </GridContainer> */}
          {/* </Card>
            </GridItem>
          </GridContainer> */}

          {/* <div className={classes.sectionTestimonials}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  md={8}
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                  <h3 className={classes.title}>Co-created by</h3>
                  <div className={classes.ourClients}>
                    <GridContainer justify="center">
                      <GridItem md={3} sm={6}>
                        <img src={StudyAustralia} alt="Study Australia" />    
                      </GridItem>
                      <GridItem md={3} sm={6}>
                      <img src={AustradeLogo} alt="Austrade Logo" />
                      </GridItem>       
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div> */}
          {/* <div className={classes.ourClients}>
          <GridContainer justify="center">
              <GridItem md={3} sm={3}>
                <img src={LogosUWA} alt="University of Western Australia" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosCurtin} alt="Curtin University" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosECU} alt="Edith Curtin University" />
              </GridItem>
              <GridItem md={3} sm={3}>
            <img src={LogosNIT} alt="NIT Australia" />
          </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem md={3} sm={3}>
                <img src={LogosJBA} alt="JBA" />
              </GridItem>
              <GridItem md={3} sm={3}>
                <img src={LogosNMTafe} alt="North Metro TAFE" />
              </GridItem>
              <GridItem md={3} sm={3}>
            <img src={Logosaiwt} alt="AIWT" />
          </GridItem>
          <GridItem md={3} sm={3}>
            <img src={LogosSMTAFE} alt="South Metro TAFE" />
          </GridItem>
            </GridContainer>
          </div> */}
        </div>
      </div>
      {/* <div className={classes.ourClients}>
      <GridContainer justify="center">
          <GridItem md={3} sm={6}>
                <img src={LogosSpacecubed} alt="Spacecubed" />
          </GridItem>
          <GridItem md={3} sm={6}>
                <img src={LogosCET} alt="CET" />
          </GridItem>
        </GridContainer>
      </div> */}
      <div className={classes.sectionTestimonials}>
        <div className={classes.container}>
          {/* <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <h3 className={classes.title}>This initiative is made possible through the support of Lotterywest, the WA Government and StudyPerth</h3>
              <div className={classes.ourClients}>
                <GridContainer justify="center">
                <GridItem md={3} sm={2}>
            <img src={LogosWAGovt} alt="WA Govt" />
          </GridItem>   
          <GridItem md={3} sm={2}>
                <img src={LogosStudyPerth} alt="StudyPerth" />
          </GridItem>
          <GridItem md={3} sm={2}>
                <img src={LogosLotteryWest} alt="Lottery West" />
          </GridItem>
                </GridContainer>
              </div>
            </GridItem>
            </GridContainer> */}
          {/* <div className={classes.sectionTestimonials}>
        <div className={classes.container}> */}
          {/* <GridContainer>
            <GridItem
              md={8}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <h3 className={classes.title}>Powered by</h3>
              <div className={classes.ourClients}>
                <GridContainer justify="center">
                  <GridItem md={12} sm={12}>
                    <img src={FRANKlogo} alt="franked.io" />
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer> */}
          {/* </div>
      </div> */}
        </div>
      </div>
    </div>
  );
}
