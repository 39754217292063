import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Schedule from '@material-ui/icons/Schedule';
import Message from '@material-ui/icons/Message';
import Movie from '@material-ui/icons/Movie';
import List from '@material-ui/icons/List';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import pillsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js';

const useStyles = makeStyles(pillsStyle);

export default function SectionSchedule() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem
          md={12}
          sm={12}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>
            Study Australia Entrepreneurship Challenge schedule of events
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <NavPills
            color="info"
            className="custom-flex-pill"
            tabs={[
              {
                // tabButton: 'Day 1: 22 August 2022',
                tabButton: (
                  <span>
                    <p>
                      Day 1
                      <br />
                      22 August 2022
                    </p>
                  </span>
                ),
                tabIcon: Dashboard,
                tabContent: (
                  <span>
                    <p>
                      <strong>
                        Learning mode: Virtual orientation Day on Zoom.com
                      </strong>
                    </p>
                    <p>
                      <strong>Attendance: Attendance is compulsory</strong>
                    </p>
                    <p>
                      <strong>Timezone: IST time zone</strong>
                    </p>
                    <p>
                      9.30am Program welcome: franked.io introduction
                      <br />
                      9.45am Program welcome: Dr Monica Kennedy, Senior
                      Investment and Trade Commissioner, Mumbai
                      <br />
                      9.50am Program agenda & attendance
                      <br />
                      10.20am Speed-networking activity for students
                      <br />
                      10.55am Break with musical interlude
                      <br />
                      11.05am Important information about your mentor and mentor
                      best practice tips
                      <br />
                      11.15am Learn how to work well with your team.
                      <br />
                      11. 35am Invitation to join franked.io LinkedIn/Facebook
                      channels and Study Australia channels
                      (Facebook/Instagram/LinkedIn)
                      <br />
                      11.45am Mentor acknowledgment
                      <br />
                      11.50am Meet your mentor introductions
                      <br />
                      12.30pm Wrap up and close
                    </p>
                    <br />
                    <p>
                      <strong>
                        What happens after the Orientation Day 1 Summit
                        completes?{' '}
                      </strong>
                    </p>
                    <p>
                      1){' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      will email you the contact details of your mentor. You are
                      responsible for getting in touch with them asap and
                      setting up a time to meet.
                    </p>
                    <p>
                      2) You should also setup your team communication channel
                      e.g. Whatsapp group, FB messenger, Slack and keep
                      communicating with your team and mentor for your homework
                      tasks.
                    </p>
                    {/* <p>
                      3) You should login and watch the video courses that{' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      will give you access to. These courses go over the content
                      covered in SAEC and by watching it before SAEC kicks off;
                      you give yourself an advantage
                    </p>
                    <p>
                      <strong>
                        IS THERE ANYTHING DUE FOR SUBMISSION AFTER THIS
                        ORIENTATION DAY 1?{' '}
                      </strong>
                    </p>
                    <p>
                      Yes, you and your team will need to submit your chosen
                      challenge problem,your team name and team logo to us on{' '}
                      <strong>25th August 2022.</strong> We will introduce these
                      challenge problems to you at the Virtual Orientation Day
                      on 29th August 2022.
                    </p> */}
                  </span>
                ),
              },
              {
                // tabButton: 'Day 2: 25 August 2022',
                tabButton: (
                  <span>
                    <p>
                      Day 2
                      <br />
                      25 August 2022
                    </p>
                  </span>
                ),
                tabIcon: Schedule,
                tabContent: (
                  <span>
                    <p>
                      <strong>Learning mode: Virtual summit on Zoom.com</strong>
                      <br />
                    </p>
                    <p>
                      <strong>Attendance: Attendance is compulsory.</strong>
                    </p>
                    <p>
                      <strong>Timezone: IST time zone</strong>
                    </p>
                    <p>
                      9.15am onwards DJ playing to welcome students
                      <br />
                      9.30am Program welcome, facilitator & agenda for Day 2
                      <br />
                      9.45am Session open: Ms Melissa Banks, Head of
                      International Education Centre of Excellence, Austrade
                      <br />
                      9.50am Speed-networking activity for students
                      <br />
                      10.05am Get to know you activity
                      <br />
                      10.10am Introduction to SAEC judges and prizes
                      <br />
                      10.20am Inspiring keynote speaker, Dr. Uttam Mittal
                      <br />
                      10.40am Short break with musical interlude
                      <br />
                      10.50am Four Academic guest speakers on theme topics in 4
                      separate breakout rooms
                      <br />
                      <ul className="custom-ul-margin-bottom">
                        <li>
                          Digital Health: Ms. Farah Magrabi, Australian
                          Institute of Health Innovation, Macquarie University
                        </li>
                        <li>
                          Cybersecurity: Mr. Ibrahim Khalil, Royal Melbourne
                          Institute of Technology
                        </li>
                        <li>
                          Circular Economy: Mr. Ali Abbas, University of Sydney
                        </li>
                        <li>
                          Creative Industries: Mr. Michael Balfour, University
                          of New South Wales
                        </li>
                      </ul>
                      11.15am Masterclasses (in 4 breakout rooms): Introduction
                      to entrepreneurship, start-ups and growth mindset.
                      Introduction to the Business Model Canvas with{' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      facilitators covering topics such as customer segments and
                      value proposition.
                      <br />
                      12.15pm Panel session: Entrepreneurship panel with Mr.
                      Nimrod Klayman from University of Queensland Ventures, Mr.
                      Murray Hurps from University of Technology Sydney & Dr. Jo
                      Hawkins from University of Western Australia
                      <br />
                      12.40pm Reflections & preparing for Day 3
                      <br />
                      1.00pm Summit close
                      <br />
                    </p>
                    <br />
                    <p>
                      <strong>
                        What happens after the Day 2 Summit completes?{' '}
                      </strong>
                    </p>
                    <p>
                      1) You and your team should keep communicating and
                      discussing your idea and continue to develop it further
                      for the remainder of the day. You will need to delegate
                      tasks in your team as time is very tight
                    </p>
                    <p>
                      2) You should arrange to meet with your mentor for any
                      guidance on your idea development
                    </p>
                    <p>
                      3) We encourage you to attend the optional Masterclasses:
                    </p>
                    <p>
                      <strong>26 August: 11.00-12.00pm (IST Timezone):</strong>{' '}
                      Topic will be on the Business Model Canvas
                    </p>
                    <p>
                      <strong>30 August: 11.00-12.00pm (IST Timezone):</strong>{' '}
                      Topic will be on prototyping ideas with Guest speaker, Mr.
                      Lucas Hakewill
                    </p>
                    <br />
                    <p>
                      <strong>
                        Is there anything due for submission after Day 2?{' '}
                      </strong>
                    </p>
                    <p>
                      No, there is nothing due for submission. But use this time
                      to keep planning and developing your idea.
                    </p>
                  </span>
                ),
              },
              {
                // tabButton: 'Day 3: 31 August 2022',
                tabButton: (
                  <span>
                    <p>
                      Day 3
                      <br />
                      31 August 2022
                    </p>
                  </span>
                ),
                tabIcon: List,
                tabContent: (
                  <span>
                    <p>
                      <strong>Learning mode: Virtual Summit on Zoom.com</strong>
                      <br />
                    </p>
                    <p>
                      <strong>Attendance: Attendance is compulsory.</strong>
                    </p>
                    <p>
                      <strong>Timezone: IST time zone</strong>
                    </p>
                    <p>
                      9.15am onwards DJ playing to welcome students as they
                      enter Zoom event
                      <br />
                      9.30am Welcome & agenda. Shared learnings from Day 2
                      <br />
                      9.45am Session Open: Mr Vik Singh, Program Director,
                      Digital Education Hub, Austrade
                      <br />
                      9.50am Yidaki (didgeridoo) demonstration and introduction
                      to Indigenous culture by Mr. Josh Oshlack, Director of
                      Rhythm Village. Interactive activity with students.
                      <br />
                      10.05am Inspiring Keynote Speaker: Ms. Yvonne Power,
                      Co-founder and COO, Village Energy
                      <br />
                      10.25am Short break with musical interlude
                      <br />
                      10.40am Four Academic guest speakers on theme topics in 4
                      separate breakout rooms
                      <br />
                      <ul className="custom-ul-margin-bottom">
                        <li>
                          Ms. Usha Iyer-Raniga from Royal Melbourne Institute of
                          Technology for Circular Economy
                        </li>
                        <li>
                          Mr. Barney Tan from University of New South Wales for
                          Digital Health
                        </li>
                        <li>
                          Mr. Levi Swann from Queensland University of
                          Technology for Creative Industries
                        </li>
                        <li>
                          Mr. Andrew Woodward from Edith Cowan University on
                          Cybersecurity
                        </li>
                      </ul>
                      11.00am Masterclasses (in 4 breakout rooms): Introduction
                      to the Business Model Canvas with{' '}
                      <a href="http://franked.io" target="new window">
                        franked.io
                      </a>{' '}
                      facilitators covering topics such as channels,costs and
                      revenue
                      <br />
                      11.45am Learn how to create a video pitch and prepare for
                      your live pitch
                      <br />
                      12.30pm Wrap up
                      <br />
                      12.45pm Summit close
                      <br />
                    </p>
                    <br />
                    <p>
                      <strong>
                        What happens after the Day 3 Summit completes?
                      </strong>
                    </p>
                    <p>
                      1) You and your team should keep communicating and
                      discussing your idea and developing it further for the
                      remainder of the day. You will need to delegate tasks in
                      your team as time is very tight
                    </p>
                    <p>
                      2) You should arrange to meet with your mentor for any
                      guidance on your idea development
                    </p>
                    <p>
                      3) We encourage you to attend the optional Masterclass on{' '}
                      <strong>
                        1 September: 11.00-12.00pm (IST Timezone):
                      </strong>{' '}
                      Topic will be on the presentation and pitching skills
                    </p>
                    <p>
                      <strong>
                        Is there anything due for submission after this day 3
                        summit?{' '}
                      </strong>
                    </p>
                    <p>
                      Yes, you and your team will need to submit your 3 minute
                      video pitch of your idea and your Powerpoint slidedeck to
                      us by <strong>12pm 6th September 2022.</strong> We will
                      show you how to create a video pitch and what to include
                      in it at the Day 3 Summit itself.
                    </p>
                  </span>
                ),
              },
              {
                // tabButton: 'Video pitch due: 6 September 2022',
                tabButton: (
                  <p>
                    Video pitch due <br />6 September 2022{' '}
                  </p>
                ),
                tabIcon: Movie,
                tabContent: (
                  <span>
                    <p>
                      <strong>TIMEZONE:</strong> IST time zone
                    </p>
                    <h2>
                      <strong>Video Pitch</strong>
                    </h2>
                    <p>
                      Your team is responsible for submitting a 3 minute video
                      pitch and your 5 slide Powerpoint deck to us by{' '}
                      <strong>12pm 6th September 2022.</strong> We will show you
                      how to create a video pitch and what to include in it at
                      the Day 3 Virtual Summit <br />
                    </p>
                    <p>
                      <strong>
                        After you submit your video pitch, please prepare for
                        your in-person pitch event which kicks off at 2pm on
                        23rd September{' '}
                      </strong>
                    </p>
                    <p>
                      Each of your 5 team members will be able to speak at the
                      pitch event on 23rd September 2022.
                      <br />
                    </p>
                    {/* <p>
                      This pitch will be 3 minutes long and cover the same
                      content as the video pitch. All remote and participants will be able to attend this event via a
                      livestream. <br />
                    </p> */}
                    <p>
                      Your team can use a 5 slide Powerpoint in your live pitch.
                      <br />
                    </p>
                  </span>
                ),
              },
              {
                // tabButton: 'Day 4 Live pitch due: 23 September 2022',
                tabButton: (
                  <p>
                    Live pitch due <br /> 23 September 2022
                  </p>
                ),
                tabIcon: Message,
                tabContent: (
                  <span>
                    <h2>
                      <strong>Live Pitch</strong>
                    </h2>
                    <p>
                      <strong>Learning mode:</strong> In-person
                      <br />
                    </p>
                    <p>
                      <strong>Venue:</strong> The Imperial, New Delhi, India
                    </p>
                    <p>
                      <strong>Attendance:</strong> In-person attendance is
                      compulsory for 5 finalist teams
                    </p>
                    <p>
                      <strong>Timezone:</strong> IST time zone
                    </p>
                    <p>
                      2.00pm Official pitch event welcome and agenda.
                      Introduction to the 3 judges and event background
                      <br />
                      2.20pm Official welcome remarks
                      <br />
                      2.30pm Pitching commences: Speakers from each of the 5
                      teams will pitch their idea 1 by 1 to the 3 judges
                      <br />
                      3.30pm Break
                      <br />
                      3.50pm Winners announced
                      <br />
                      4.00pm Final words and event conclusion
                      <br />
                    </p>
                  </span>
                ),
              },
            ]}
          />
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={8} lg={6}>
              <NavPills
                color="primary"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 3, md: 4 },
                  contentGrid: { xs: 12, sm: 9, md: 8 }
                }}
                tabs={[
                  {
                    tabButton: "Dashboard",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Schedule",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem> */}
      </GridContainer>
    </div>
  );
}
