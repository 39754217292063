const TeamsData = [
  {
    id: 1,
    imageURL: require('assets/img/teamProfiles/Econominds.png'),
    place: '1st Place Team',
    teamName: 'Econominds',
    theme: 'Circular Economy',
    youtube: 'https://www.youtube.com/watch?v=dLug8BWJpHg',
  },
  {
    id: 2,
    imageURL: require('assets/img/teamProfiles/EKTA.png'),
    place: '2nd Place Team',
    teamName: 'Ekta',
    theme: 'Digital Health',
    youtube: 'https://youtu.be/euiz5izF3_E',
  },
  {
    id: 3,
    imageURL: require('assets/img/teamProfiles/CanGoRoots.png'),
    place: 'Most Innovative Team',
    teamName: 'CanGoRoots',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/cENnV_krA9U',
  },
  {
    id: 4,
    imageURL: require('assets/img/teamProfiles/Flame.png'),
    place: 'Finalist Team',
    teamName: 'Flame',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/gd3VnTaA0t4',
  },
  {
    id: 5,
    imageURL: require('assets/img/teamProfiles/MVPs.png'),
    place: 'Finalist Team',
    teamName: 'MVP`s',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/FKaYcBmHSnc',
  },
  {
    id: 6,
    imageURL: require('assets/img/teamProfiles/BruteForce.png'),
    place: 'Participating Team',
    teamName: 'BruteForce',
    theme: 'Cybersecurity',
    youtube: 'https://youtu.be/7bm00xIuJ0E',
  },
  {
    id: 7,
    imageURL: require('assets/img/teamProfiles/HackerCellsASE.png'),
    place: 'Participating Team',
    teamName: 'Hacker Cells ASE',
    theme: 'Digital Health',
    youtube: 'https://youtu.be/7x-eggbM57k',
  },
  {
    id: 8,
    imageURL: require('assets/img/teamProfiles/StyleQ-rators.png'),
    place: 'Participating Team',
    teamName: 'The Style Q-rators',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/t1g5ony-3v4',
  },
  {
    id: 9,
    imageURL: require('assets/img/teamProfiles/Squarz.png'),
    place: 'Participating Team',
    teamName: 'Squarz',
    theme: 'Cybersecurity',
    youtube: 'https://tinyurl.com/squarz-team12',
  },
  {
    id: 10,
    imageURL: require('assets/img/teamProfiles/Synergy.png'),
    place: 'Participating Team',
    teamName: 'Synergy',
    theme: 'Creative Industries',
    youtube: 'https://youtu.be/qJvTHUPn8Qw',
  },
  {
    id: 11,
    imageURL: require('assets/img/teamProfiles/404.inc.png'),
    place: 'Participating Team',
    teamName: '404.Inc',
    theme: 'Cybersecurity',
    youtube: 'https://youtu.be/oP6gcYnWL8Y',
  },
  // {
  //   id: 12,
  //   place: 'Participating Team',
  //   teamName: 'Eagle-hunter',
  //   theme: 'Cybersecurity',
  //   youtube: 'https://youtu.be/BqlRJZDTbhM',
  // },
  {
    id: 13,
    imageURL: require('assets/img/teamProfiles/TeamInsideIndia.png'),
    place: 'Participating Team',
    teamName: 'Team Inside India',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/98EKPzF0Wjc',
  },
  {
    id: 14,
    imageURL: require('assets/img/teamProfiles/UrbanSettlers.png'),
    place: 'Participating Team',
    teamName: 'Urban Settlers',
    theme: 'Creative Industries',
    youtube: 'https://youtu.be/LUbCm1nSPEY',
  },
  {
    id: 15,
    imageURL: require('assets/img/teamProfiles/WALL.png'),
    place: 'Participating Team',
    teamName: 'WALL',
    theme: 'Digital Health',
    youtube: 'https://www.youtube.com/watch?v=eVijU1kPa1A',
  },
  {
    id: 16,
    imageURL: require('assets/img/teamProfiles/TeamVenera.png'),
    place: 'Participating Team',
    teamName: 'Team Venera',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/f3mDzcW6eG4',
  },
  {
    id: 17,
    imageURL: require('assets/img/teamProfiles/5Horsepower.png'),
    place: 'Participating Team',
    teamName: '5 Horsepower',
    theme: 'Digital Health',
    youtube: 'https://youtu.be/uufAoRd56ec',
  },
  {
    id: 18,
    imageURL: require('assets/img/teamProfiles/MarketNeurons.png'),
    place: 'Participating Team',
    teamName: 'Market Neurons',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/fMqh-xRzMR0',
  },
  {
    id: 19,
    imageURL: require('assets/img/teamProfiles/Envitro.png'),
    place: 'Participating Team',
    teamName: 'Envitro',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/yCkigsvmoZE',
  },
  {
    id: 20,
    imageURL: require('assets/img/teamProfiles/Mavericks.png'),
    place: 'Participating Team',
    teamName: 'Mavericks',
    theme: 'Digital Health',
    youtube: 'https://youtu.be/9STQj9b1PL4',
  },
  {
    id: 21,
    imageURL: require('assets/img/teamProfiles/TeamInspo.png'),
    place: 'Participating Team',
    teamName: 'Team Inspo',
    theme: 'Cybersecurity',
    youtube: 'https://youtu.be/16jeXBrhrKQ',
  },
  {
    id: 22,
    imageURL: require('assets/img/teamProfiles/Concept.png'),
    place: 'Participating Team',
    teamName: 'Concept',
    theme: 'Circular Economy',
    youtube: 'https://youtu.be/yXT0M7mKtr8',
  },
  {
    id: 23,
    imageURL: require('assets/img/teamProfiles/TrailBlazers.png'),
    place: 'Participating Team',
    teamName: 'Trail Blazers',
    theme: 'Digital Health',
    youtube: 'https://youtu.be/lzVL-RP8uzs',
  },
  {
    id: 24,
    imageURL: require('assets/img/teamProfiles/GlobalGoblins.png'),
    place: 'Participating Team',
    teamName: 'Global goblins',
    theme: 'Creative Industries',
    youtube: 'https://youtu.be/IMj0zPeEESY',
  },
  {
    id: 25,
    imageURL: require('assets/img/teamProfiles/TheMavericks.png'),
    place: 'Participating Team',
    teamName: 'THE MAVERICKS',
    theme: 'Creative Industries',
    youtube: 'https://youtu.be/bUkS6n2ukmY',
  },
];
export default TeamsData;

// const TeamsData = [
//   {
//     id: 24,
//     name: 'Econominds',
//     url: 'https://www.youtube.com/watch?v=dLug8BWJpHg',
//     motto: '1st',
//   },
//   {
//     id: 27,
//     name: 'Ekta',
//     url: 'https://youtu.be/euiz5izF3_E',
//     motto: '2nd',
//   },
//   {
//     id: 32,
//     name: "MVP's",
//     url: 'https://youtu.be/FKaYcBmHSnc',
//     motto: 'Finalist',
//   },
//   {
//     id: 17,
//     name: 'Flame',
//     url: 'https://youtu.be/gd3VnTaA0t4',
//     motto: 'Finalist',
//   },
//   {
//     id: 2,
//     name: 'CanGoRoots',
//     url: 'https://youtu.be/cENnV_krA9U',
//     motto: 'Most Innovative',
//   },
//   {
//     id: 1,
//     name: 'BruteForce',
//     url: 'https://youtu.be/7bm00xIuJ0E',
//     motto: '',
//   },
//   {
//     id: 10,
//     name: 'Hacker Cells ASE',
//     url: 'https://youtu.be/7x-eggbM57k',
//     motto: '',
//   },
//   {
//     id: 11,
//     name: 'The Style Q-rators',
//     url: 'https://youtu.be/t1g5ony-3v4',
//     motto: '',
//   },
//   {
//     id: 12,
//     name: 'Squarz',
//     url: 'https://tinyurl.com/squarz-team12',
//     motto: '',
//   },
//   {
//     id: 13,
//     name: 'Synergy',
//     url: 'https://youtu.be/qJvTHUPn8Qw',
//     motto: '',
//   },
//   {
//     id: 14,
//     name: '404.Inc',
//     url: 'https://youtu.be/oP6gcYnWL8Y',
//     motto: '',
//   },
//   {
//     id: 15,
//     name: 'Eagle-hunter',
//     url: 'https://youtu.be/BqlRJZDTbhM',
//     motto: '',
//   },
//   {
//     id: 16,
//     name: 'Team Inside India',
//     url: 'https://youtu.be/98EKPzF0Wjc',
//     motto: '',
//   },
//   {
//     id: 20,
//     name: 'Urban Settlers',
//     url: 'https://youtu.be/LUbCm1nSPEY',
//     motto: '',
//   },
//   {
//     id: 23,
//     name: 'WALL',
//     url: 'https://www.youtube.com/watch?v=eVijU1kPa1A',
//     motto: '',
//   },
//   {
//     id: 26,
//     name: 'Team Venera',
//     url: 'https://youtu.be/f3mDzcW6eG4',
//     motto: '',
//   },
//   {
//     id: 28,
//     name: '5 Horsepower',
//     url: 'https://youtu.be/uufAoRd56ec',
//     motto: '',
//   },
//   {
//     id: 3,
//     name: 'Market Neurons',
//     url: 'https://youtu.be/fMqh-xRzMR0',
//     motto: '',
//   },
//   {
//     id: 31,
//     name: 'Envitro',
//     url: 'https://youtu.be/yCkigsvmoZE',
//     motto: '',
//   },
//   {
//     id: 33,
//     name: 'Mavericks',
//     url: 'https://youtu.be/9STQj9b1PL4',
//     motto: '',
//   },
//   {
//     id: 42,
//     name: 'Team Inspo',
//     url: 'https://youtu.be/16jeXBrhrKQ',
//     motto: '',
//   },
//   {
//     id: 6,
//     name: 'Concept',
//     url: 'https://youtu.be/yXT0M7mKtr8',
//     motto: '',
//   },
//   {
//     id: 7,
//     name: 'Trail Blazers',
//     url: 'https://youtu.be/lzVL-RP8uzs',
//     motto: '',
//   },
//   {
//     id: 8,
//     name: 'Global goblins',
//     url: 'https://youtu.be/IMj0zPeEESY',
//     motto: '',
//   },
//   {
//     id: 9,
//     name: 'THE MAVERICKS',
//     url: 'https://youtu.be/bUkS6n2ukmY',
//     motto: '',
//   },
// ];
// export default TeamsData;
