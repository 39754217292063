import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import List from '@material-ui/core/List';

import servicesStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js';
import { ListItem } from '@material-ui/core';

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textLeft
          )}
        >
          <h2 className={classes.title}>
            What is the Study Australia Entrepreneurship Challenge about?
          </h2>
          <List>
            <ListItem>
              You will be teamed up with students from your own high school.
              Your team will compete against 30+ other teams from other high
              schools. It will be a real competition! Your team will work to
              solve real global challenges. You will learn the latest start-up
              and innovation thinking and have your chance to pitch your ideas
              at a Gala Pitch Event in-person in India. You will meet Australian
              academics and entrepreneurs. The top 3 teams will win prizes and
              you will gain valuable work experience and practical skills to add
              to your resume, LinkedIn and to talk about at interviews.
            </ListItem>
          </List>
          <h3 className={classes.title}>How does it work?</h3>
          <List>
            <ListItem>
              1) You will be part of a team of 5 students from your own high
              school.
            </ListItem>
            <ListItem>
              2) Each team may have students with different interests so you may
              be teamed up with future lawyers, marketers, software developers,
              historians and so on. That is how you will produce exceptional
              ideas!
            </ListItem>
            <ListItem>
              3) You can work virtually or choose to meet your team in-person
              during the Challenge; we will leave that up to you.
            </ListItem>
            {/* <ListItem>
              4) Some students may also be participating from outside Australia
              - so you will be working in virtual teams.
            </ListItem> */}
          </List>
          <h3 className={classes.title}>What are the dates of the program?</h3>
          <List>
            <ListItem>(All times are in IST Timezone)</ListItem>
            <ListItem>
              1)
              <strong style={{ display: 'contents' }}> Day 1</strong>: Virtual
              Orientation/Summit - May 6th 2024, 10am - 1pm (Compulsory
              attendance)
            </ListItem>
            <ListItem>
              2)
              <strong style={{ display: 'contents' }}> Day 2</strong>: Virtual
              Summit - May 8th 2024, 10am - 1pm (Compulsory attendance)
            </ListItem>
            <ListItem>
              3){' '}
              <strong style={{ display: 'contents' }}>
                Bonus Pitching Masterclass
              </strong>
              : May 13th 2024 (Attendance from 11am - 12pm compulsory for at
              least one team member)
            </ListItem>
            {/* <ListItem>
              4) Bonus  Masterclass #2: 30th August, 11.00am - 12.00pm (Optional
              attendance)
            </ListItem>
            <ListItem>
              5) <strong style={{ display: 'contents' }}>Day 3:</strong> 31st
              August 2022: 9.30am - 12.30pm: Virtual Summit #3. On Zoom.com
              (Compulsory attendance)
            </ListItem>
            <ListItem>
              6) Bonus Masterclass #3: 1st September, 11.00am - 12.00pm
              (Optional attendance)
            </ListItem> */}
            <ListItem>
              4)
              <strong style={{ display: 'contents' }}> Day 3</strong>: In-person
              pitch event for 5 finalist teams - May 31st 2024. In-Person Gala
              Event for 5 finalist teams - to be held in India, city to be
              confirmed.
            </ListItem>
          </List>
          <h3 className={classes.title}>
            Is attendance at all events compulsory?
          </h3>
          <List>
            <ListItem>
              1) Yes attendance is compulsory for all team members at both of
              the virtual summits. Attendance is compulsory for at least one
              team member for the bonus pitching masterclass from 11am - 12pm
              with the option to stay from 12-12.30pm for the opportunity to ask
              questions about your team's pitch.
            </ListItem>
            <ListItem>
              2) Failure to attend the Virtual Orientation Summit without prior
              notification will make you ineligible for the remainder of the
              program.
            </ListItem>
          </List>
          {/* <h3 className={classes.title}>
            How will you be introduced to your teams?
          </h3> */}
          {/* <List>
            <ListItem>
              1) Your institution will introduce you to your team before 22nd
              August 2022.
            </ListItem>
            <ListItem>
              2) We ask that you get in touch with your team to introduce
              yourself at the earliest opportunity and get to know each other.
            </ListItem>
          </List> */}
          <h3 className={classes.title}>
            How much time should you commit to this program?
          </h3>
          <List>
            <ListItem>
              1) Attendance at both virtual summits is compulsory. You should
              also be available for the May 31st live pitch event, in case your
              team is a finalist in this challenge.
            </ListItem>
            <ListItem>
              2) Failure to attend the virtual orientation day on May 6th 2024
              will mean that you are ineligible to continue the program.
            </ListItem>
            <ListItem>
              3) Outside attending the virtual events, you will need to commit a
              further 5-7 hours per week for the duration of the program from
              6th - 31st May 2024.
            </ListItem>
          </List>
          <h3 className={classes.title}>
            How will you be introduced to your mentor?
          </h3>
          <List>
            <ListItem>
              1) You will have the opportunity to meet your mentor 1 week before
              the challenge kick off.
            </ListItem>
            <ListItem>
              2) We ask that you get in touch with your mentor to introduce
              yourself at the earliest opportunity and get started on your
              tasks.
            </ListItem>
          </List>
          <h3 className={classes.title}>
            Is there a recommended mentoring schedule?
          </h3>
          <List>
            <ListItem>
              We leave it to you and your team and mentor to decide the best
              mentoring schedules that work for all of you. See below for a
              recommended schedule based on past programs:
            </ListItem>
            <List>
              <ListItem>
                1) 29th April - 5th May 2024 - Ideally introduce yourself to
                your mentor. Establish your meeting schedule and select your
                preferred communication platform as a team.
              </ListItem>
              <ListItem>
                2) 6th May - 12th May 2024 - Meet once or twice with your mentor
                to discuss tasks due.
              </ListItem>
              <ListItem>
                3) 12th - 16th May January 2024 - Meet once or twice with your
                mentor to prepare for your video pitch presentation.
              </ListItem>
              {/* <ListItem>4) 15th - 22nd September 2022 - Meet once with your mentor/finalise tasks/prepare for a pitch presentation.</ListItem> */}
            </List>
          </List>
          <h3 className={classes.title}>
            How will you communicate with your team?
          </h3>
          <List>
            <ListItem>
              You and your team will decide amongst yourselves what the
              preferred platforms and tools are; we leave this up to you to
              decide.
            </ListItem>
            <ListItem>
              Communication can be 100% remote/virtual or include face-to-face
              meetings.
            </ListItem>
            <ListItem>Platforms to consider include:</ListItem>
            <List>
              <ListItem>
                1) Zoom/Microsoft Teams/Skype/Google Hangouts/Cisco Webex for
                meetings
              </ListItem>
              <ListItem>
                2) Slack/Microsoft Teams/Whatsapp/Facebook Messenger for
                Messaging/Communication
              </ListItem>
              <ListItem>
                3) Google Docs/Trello/Miro/Mural for project
                management/templates
              </ListItem>
            </List>
            <h3 className={classes.title}>
              What challenges can you expect as a participant?
            </h3>
            <List>
              <ListItem>
                1) There will be different levels of engagement from students in
                your team (like a group assignment); with some not as engaged as
                others and some dropping out altogether; learning to work in
                these challenging circumstances is part of the preparation for
                the world of work and jobs.
              </ListItem>
              <ListItem>
                2) Working as a team may also be challenging for some students;
                work with your team and mentor to address any challenges that
                you may encounter. This again is part of the learning.{' '}
              </ListItem>
              <ListItem>
                3) Be confident and learn as you go. This is what it is all
                about.
              </ListItem>
            </List>
          </List>
        </GridItem>
        {/* <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Innovation Challenge Themes</h2>
          <h5 className={classes.description}>
            We will be announcing the 2021 Innovation Challenge Themes shortly. This innovation challenge themes are centred around solving for the economic priorities and upcoming challenges faced by City of Joondalup Council. 
          </h5>
        </GridItem> */}
      </GridContainer>

      <GridContainer>
        {/* <GridItem md={3} sm={12}>
          <InfoArea
            title="1. Building Successful Businesses in Joondalup in the COVID- Safe Economy"
            description={
              <span>
                <p>
                The Joondalup economy has been hit hard by Coronavirus (COVID-19). Gross Regional Product fell by -12.4% in the June Quarter 2020. The top 3 Sectors impacted include: Accommodation and Food Services; Retail Trade and Education and Training. As Australia recovers from the health and economic impact of COVID-19, pandemic-hit businesses in Joondalup must adjust to operating in a completely new environment. In Joondalup, 42% of people work in businesses impacted by COVID-19 & 47% of businesses in the area say they have been impacted by the virus.
{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon={Gesture}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="2. Skilling the Future Workforce of Joondalup"
            description={
              <span>
                <p>
                  {" "}
                  By 2030, millions of today’s jobs will be lost to automation according to McKinsey Global Institute.  However, there will also be a vast array of new jobs available to university graduates - many related to innovation and digital technologies. Directly due to COVID-19, local Joondalup jobs are forecast to fall by -8.3% in the June Quarter 2020. This equates to a fall of 4,362 local jobs.{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon={Build}
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="3. International Tourism in a post-COVID World"
            description={
              <span>
                <p>
                Tourism is an important economic driver for Western Australia and will play an important role in the State’s recovery post COVID-19. As background, in 2017/2018 there were 2.7 million visitors in the City of Joondalup alone. But only 5.48% of total tourists to Perth, visit the Joondalup region and there is a lot of opportunity to attract more of these visitors. Of the visitors coming to Joondalup, 48.9% are international visitors staying overnight, 38.5% are domestic day trippers and 15.3% are domestic visitors staying overnight. With intra and inter-state borders closed to travellers for much of 2020, visitors to WA’s key tourist hotspots have dropped significantly. 
{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon="mode_edit"
            iconColor="warning"
          />
        </GridItem>
        <GridItem md={3} sm={12}>
          <InfoArea
            title="3. Building Cyber Skills in the Economy"
            description={
              <span>
                <p>
                Strong cyber security is a fundamental element of our growth and prosperity in a global economy. Joondalup has emerged as the hub of cyber security in WA. The Cyber Security cooperative research centre (CRC) was set up in 2018 with $50 million in Federal funding. Joonadalup has a core focus relating to cybersecurity, and this is to encourage and attract students into the region to study in this rapidly evolving field and to be able to support these students with programs that enhance their  employability skills and connect them to future employment.{" "}
                </p>
                <a href="#pablo">Find more...</a>
              </span>
            }
            icon="mode_edit"
            iconColor="warning"
          />
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
